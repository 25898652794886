import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ScheduleView from '../views/home/ScheduleView.vue'
import CoreDataView from '../views/home/CoreDataView.vue'
import AdminCoreDataView from '../views/admin/AdminCoredataView.vue'
import AdminView from '../views/AdminView.vue'
import JustView from '../views/JustView.vue'
import AdminLogView from '@/views/admin/AdminLogView.vue'
import AdminCalllistView from '@/views/admin/AdminCalllistView.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/view',
    name: 'view',
    component: JustView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [{
      path: '/home',
      component: ScheduleView,
      meta: { requiresAuth: true }
    },
    {
      path: '/coredata',
      component: CoreDataView,
      meta: { requiresAuth: true }
    },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [{
      path: '/admin',
      component: ScheduleView,
      meta: { requiresAuth: true }
    },
    {
      path: '/admincoredata',
      component: AdminCoreDataView,
      meta: { requiresAuth: true }
    },
    {
      path: '/adminlog',
      component: AdminLogView,
      meta: { requiresAuth: true }
    },
    {
      path: '/admincalllist',
      component: AdminCalllistView,
      meta: { requiresAuth: true }
    },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const loggedIn = localStorage.getItem('user')
  if (requiresAuth && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
