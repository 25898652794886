import Edit from '../services/edit.service'

const editStates = {
    item: null,
    callees: null,
    cdpns: null,
}

export default {
    namespaced: true,
    state: editStates,
    getters: {
        scheduleItem(state) {
            return state.item;
        },
        Callees(state) {
            return state.callees;
        },
        Cdpns(state) {
            return state.cdpns;
        },
    },
    actions: {
        getItem({ commit }, scheduleid) {
            return Edit.getItem(scheduleid).then(
                res => {
                    commit('setItem', res.data)
                    return res.data.item
                },
            );
        },
        getCallees({ commit }) {
            return Edit.getCallees().then(
                res => {
                    commit('setCallees', res.data)
                    return res.data
                },
            );
        },
        getCdpns({ commit }) {
            return Edit.getCdpns().then(
                res => {
                    commit('setCdpns', res.data)
                    return res.data
                },
            );
        },
        setCallee({ commit }, schedule) {
            return Edit.setCallee(schedule);
        },
        setCdpn({ commit }, schedule) {
            return Edit.setCdpn(schedule);
        },
        splitSchedule({ commit }, schedule) {
            return Edit.splitSchedule(schedule);
        },
        sendRequest({ commit }, schedule) {
            return Edit.sendRequest(schedule);
        }
    },
    mutations: {
        setItem(state, item) {
            state.item = item.item;
        },
        setCallees(state, callees) {
            state.callees = callees.callees;
        },
        setCdpns(state, cdpns) {
            state.cdpns = cdpns.cdpns;
        },

    },
}