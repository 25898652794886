<template lang="">
    <div><div v-if="calleeinf" class="coredataedit">
        <label>Region: <a @click="ChangeRegion">{{calleeinf.rname}}</a></label><br>
        <div><label>Name: </label>
        <input type="text" v-model="calleeinf.name" @change="Updated"></div>
        <div>
        <label>Username: </label>
        <input type="text" v-model="calleeinf.username" @change="Updated"></div>
        <div>
            <label>Ansage: </label>
        <a target="_google" href="https://cloud.google.com/text-to-speech/docs/ssml?hl=de">&#x2753;</a>
        <textarea v-model="calleeinf.audio" @change="Updated" @focus="Resize"></textarea>
        <audio v-if="!loading&&audiourl" controls>
            <source :src="audiourl" type="audio/mpeg">
        </audio>
        </div><div>
        <label>Absage: </label>
        <textarea v-model="calleeinf.audionoconnect" @change="Updated" @focus="Resize"></textarea>
        <audio v-if="!loading&&audiourlnoconn" controls>
            <source :src="audiourlnoconn" type="audio/mpeg">
        </audio>
        </div>
        <div>
        <label>Adresse: </label>
        <textarea v-model="calleeinf.adress" @change="Updated" @focus="Resize"></textarea></div>
        <div><label>Email: </label>
        <input type="text" v-model="calleeinf.email"  @change="Updated"></div>
        <div><label>Rechte: </label>
        <input type="text" v-model="calleeinf.rights"  @change="Updated"></div>
        <div><select v-model=deletecdpn>
            <option disabled value="">Nummer löschen</option>
            <option v-for="cdpn in calleeinf.cdpns" :key="cdpn" :value="cdpn">{{cdpn}}</option>
        </select></div>
        <div>
            <label>Ersatzziel: </label>
            <select v-model=replcdpn @change="ChangeReplCdpn">
            <option value="">Kein Ersatzziel</option>
            <option v-for="cdpn in calleeinf.cdpns" :key="cdpn" :value="cdpn">{{cdpn}}</option>
        </select>
        <input v-if="replcdpn" v-model="calleeinf.secconnect" type="number" class="secs" @change="Updated">
        <label v-if="replcdpn"> Sekunden bis zum Ersatzziel</label>
        </div><div>
        <label>Standardnummer: </label>
        <select v-model=standcdpn @change="ChangeStandCdpn">
            <option v-for="cdpn in calleeinf.cdpns" :key="cdpn" :value="cdpn">{{cdpn}}</option>
        </select></div><div>
        <label>Neues Passwort:</label>
        <input type="text" v-model="newpassword"></div><div>
        <label>Neue Nummer:</label>
        <input type="text" v-model="newcdpn"></div>
        <TheLoadingAnimation v-if="loading"/><div>
        <button @click="Switch(-1)">&#x2190;</button>
        <button @click="Safe">Speichern</button>
        <button @click="emit('edit',0)">Abbrechen</button>
        <button @click="PresetPopup">Voreinstellung</button>
        <button @click="Popup">Arzt löschen</button>
        <button @click="Refresh(props.calleeid)">Aktualisieren</button>
        <button @click="Switch(1)">&#x2192;</button>
        </div>
        <Modal v-model:visible="isvisible" :okButton=yesButton :cancelButton=noButton :title=title>
            <div>Wollen Sie den Arzt wirklich löschen?</div>
        </Modal>
        <Modal v-model:visible="presetpopup.isvisible" :okButton=presetpopup.safe :cancelButton=presetpopup.cancel :title=presetpopup.title :width=presetpopup.width>
            <div>
                <table v-if="calleepreset">
      <thead>
        <tr>
          <th>Startzeit</th>
          <th>Endzeit</th>
          <th>Wochentag</th>
          <th>Nummer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in calleepreset" :key="item.id">
            <td><input type="time" v-model="item.starttime"></td>
          <td><input type="time" v-model="item.endtime"></td>
          <td>
            <select v-model="item.weekday">
              <option v-for="day in weekdays" :value="day.nr" :key="day.nr">{{day.name}}</option>
            </select>
          </td>
          <td>
            <select v-model="item.cdpn">
              <option v-for="cdpn in calleeinf.cdpns" :key="cdpn" :value="cdpn">{{cdpn}}</option>
            </select>
          </td>
          <a @click="DeletePreset(item.id)">&#9746;</a>
        </tr>
      </tbody>
    </table>
    <a @click="NewPreset">Voreinstellung hinzufügen</a>
            </div>
        </Modal>
    </div></div>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsAdmin } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";

//variables
//vue
const props = defineProps({ calleeid: Number });
const emit = defineEmits(["edit"]);
const store = useStore();
const router = useRouter();
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
//calleeinf
const callees = computed(() => {
  return store.getters["admin/Callees"];
});
const calleeinf = computed(() => {
  return store.getters["admin/CalleeInf"];
});
let audiourl = ref();
let audiourlnoconn = ref();
let updated = ref(false);
let newpassword = ref();
let newcdpn = ref();
let deletecdpn = ref("");
let standcdpn = ref("");
let replcdpn = ref("");
let loading = ref(true);
let calleeid = ref();
//calleepreset
const calleepreset = computed(() => {
  return store.getters["admin/CalleePreset"];
});
const weekdays = [
  { nr: 0, name: "Montag" },
  { nr: 1, name: "Dienstag" },
  { nr: 2, name: "Mittwoch" },
  { nr: 3, name: "Donnerstag" },
  { nr: 4, name: "Freitag" },
  { nr: 5, name: "Samstag" },
  { nr: 6, name: "Sonntag" },
];

//functions
//refresh and init
function Refresh(calleeid) {
  if (admin) {
    if (audiourl.value) {
      URL.revokeObjectURL(audiourl.value);
      audiourl.value = null;
    }
    if (audiourlnoconn.value) {
      URL.revokeObjectURL(audiourlnoconn.value);
      audiourlnoconn.value = null;
    }
    store
      .dispatch("admin/getCalleeInf", calleeid)
      .then((res) => {
        standcdpn.value = res.calleeInf.cdpns[0];
        if (res.calleeInf.replcdpn) replcdpn.value = res.calleeInf.replcdpn;
      })
      .catch((err) => {
        OnError(err, router);
      });
    store
      .dispatch("admin/getCalleeAudio", calleeid)
      .then((res) => {
        const blob = new Blob([res], { type: "audio/mpeg" });
        audiourl.value = URL.createObjectURL(blob);
        loading.value = false;
      })
      .catch((err) => {
        if (err.response.status && err.response.status == 401) {
          notify(err.response.data.detail, "info");
          router.push("/");
        } else if (err.code == "ERR_NETWORK") {
          notify("Server nicht gefunde", "error");
        } else {
          console.log(err);
          loading.value = false;
          notify("Audiodatei nicht vorhanden", "error");
        }
      });
    store
      .dispatch("admin/getCalleeAudioNoConn", calleeid)
      .then((res) => {
        const blob = new Blob([res], { type: "audio/mpeg" });
        audiourlnoconn.value = URL.createObjectURL(blob);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status && err.response.status == 401) {
          notify(err.response.data.detail, "info");
          router.push("/");
        } else if (err.code == "ERR_NETWORK") {
          notify("Server nicht gefunde", "error");
        } else {
          console.log(err);
          loading.value = false;
        }
      });
    updated.value = false;
  }
}
Refresh(props.calleeid);
//safe
function Updated() {
  updated.value = true;
}
function ChangeStandCdpn() {
  store
    .dispatch("admin/setStandCdpn", {
      calleeid: calleeinf.value.calleeid,
      cdpn: standcdpn.value,
    })
    .then((res) => {
      notify(res.data.detail, "success");
      Refresh(props.calleeid);
    })
    .catch((err) => OnError(err, router));
}
function ChangeReplCdpn() {
  store
    .dispatch("admin/setReplCdpn", {
      calleeid: calleeinf.value.calleeid,
      cdpn: replcdpn.value,
    })
    .then((res) => {
      notify(res.data.detail, "success");
      Refresh(props.calleeid);
    })
    .catch((err) => OnError(err, router));
}
function Safe() {
  if (updated.value) {
    loading.value = true;
    store
      .dispatch("admin/setCalleeInf", calleeinf.value)
      .then((res) => {
        notify(res.data.detail, "success");
        Refresh(props.calleeid);
      })
      .catch((err) => {
        loading.value = false;
        OnError(err, router);
      });
  }
  if (deletecdpn.value) {
    store
      .dispatch("admin/deleteCdpn", {
        calleeid: calleeinf.value.calleeid,
        cdpn: deletecdpn.value,
        newcdpn: newcdpn.value,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        Refresh(props.calleeid);
      })
      .catch((err) => OnError(err, router));
    deletecdpn.value = "";
    return;
  }
  if (newcdpn.value) {
    const re = /^[0-9*#]+$/;
    if (!re.test(newcdpn.value)) {
      notify("Nur Ziffern erlaubt", "error");
      newcdpn.value = null;
      Refresh(props.calleeid);
      return;
    }
    store
      .dispatch("admin/newCdpn", {
        calleeid: calleeinf.value.calleeid,
        cdpn: newcdpn.value,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        Refresh(props.calleeid);
      })
      .catch((err) => OnError(err, router));
    newcdpn.value = null;
  }
  if (newpassword.value) {
    store
      .dispatch("admin/setCalleePw", {
        calleeid: calleeinf.value.calleeid,
        password: newpassword.value,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        Refresh(props.calleeid);
      })
      .catch((err) => OnError(err, router));
    newpassword.value = null;
  }
}
//switch callee
function Switch(direction) {
  deletecdpn.value = "";
  replcdpn.value = "";
  const index = callees.value.findIndex(
    (obj) => obj.calleeid === calleeinf.value.calleeid
  );
  if (index === callees.value.length - 1 && direction === 1) {
    direction = -index;
    notify("Wieder am Anfang", "info");
  }
  if (index === 0 && direction === -1) {
    direction = callees.value.length - 1;
    notify("Wieder am Ende", "info");
  }
  loading.value = true;
  if (audiourl.value) {
    URL.revokeObjectURL(audiourl.value);
    audiourl.value = null;
  }
  emit("edit", callees.value[index + direction].calleeid);
  Refresh(callees.value[index + direction].calleeid);
}
function ChangeRegion() {
  store.commit("admin/setRegionId", calleeinf.value.rid);
  emit('edit',0)

}
//resize textarea
function Resize(event) {
  const textarea = event.target;
  textarea.style.height = textarea.scrollHeight - 20 + "px";
}

//Popup DeleteCallee
function DeleteCallee() {
  store
    .dispatch("admin/deleteCallee", calleeinf.value.calleeid)
    .then((res) => {
      notify(res.data.detail, "success");
      emit("edit", 0);
    })
    .catch((err) => OnError(err, router));
  isvisible.value = false;
}
let isvisible = ref(false);
const title = "Arzt löschen?";
const yesButton = { text: "Ja", onclick: DeleteCallee };
const noButton = { text: "Nein", onclick: null };
function Popup() {
  isvisible.value = true;
}
//Popup CalleePreset
function DeletePreset(id) {
  let calleepresets = [];
  calleepreset.value.forEach((preset) => {
    calleepresets.push(preset);
  });
  const index = calleepresets.findIndex((item) => item.id === id);
  if (index !== -1) {
    calleepresets.splice(index, 1);
  }
  store.commit("admin/setCalleePreset", { preset: calleepresets });
}
function NewPreset() {
  let calleepresets = [];
  if (calleepreset.value) {
    calleepreset.value.forEach((preset) => {
      calleepresets.push(preset);
    });
  }
  calleepresets.push({
    id: 0,
    starttime: "00:00",
    endtime: "00:00",
    weekday: 0,
    calleeid: calleeinf.value.calleeid,
    cdpn: calleeinf.value.cdpns[0],
  });
  store.commit("admin/setCalleePreset", { preset: calleepresets });
}
function SafePreset() {
  console.log(calleepreset.value);
  console.log(calleeinf.value.calleeid);
  if (calleepreset.value.length == 0) {
    store
      .dispatch("admin/setCalleePreset", [
        {
          id: 0,
          starttime: "00:00",
          endtime: "00:00",
          weekday: 7,
          calleeid: calleeinf.value.calleeid,
          cdpn: 0,
        },
      ])
      .then((res) => notify(res.data.detail, "success"))
      .catch((err) => OnError(err, router));
  } else {
    store
      .dispatch("admin/setCalleePreset", calleepreset.value)
      .then((res) => notify(res.data.detail, "success"))
      .catch((err) => OnError(err, router));
  }
}
const presetpopup = reactive({
  title: "Voreinstellung",
  isvisible: false,
  safe: { text: "Speichern", onclick: SafePreset },
  cancel: { text: "Abbrechen", onclick: null },
  width: "45%",
});
function PresetPopup() {
  store.commit("admin/setCalleePreset", { preset: null });
  store.dispatch("admin/getCalleePreset", props.calleeid).catch((err) => {
    OnError(err, router);
  });
  presetpopup.isvisible = true;
}
</script>
<style scoped>
a:hover {
  color: #91bd2a;
}
a {
  cursor: pointer;
}
audio {
  width: 50%;
}
textarea {
  width: 50%;
}
select {
  width: fit-content;
}
.secs {
  width: 40px;
}
.coredataedit {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
.coredataedit a {
  margin: 0 2px;
  text-decoration: none;
}
.coredataedit div {
  margin: 5px 0;
}

@media (max-width: 600px) {
  div {
    display: grid;
  }
  select,
  textarea,
  audio {
    width: 100%;
  }
}
</style>
