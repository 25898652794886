<template lang="">
    <footer>
        <table style="width:75%">
            <tr >
                <td>Hotline/Kontakt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a class="maa" href="tel:01805073100">🕻 01805 0731 00</a> *</td>
                <td></td>
                <td v-if="admin"><a target="_si" href="/manual_all/index.html">Handbuch</a></td>
                <td v-if="viewer && !admin"><a target="_si" href="/manual_1/index.html">Handbuch</a></td>
                <td  v-if="!admin && !viewer"><a target="_si" href="/manual_0/index.html">Handbuch</a></td>
                <td ><a href="#">FAQ</a></td>
            </tr>
            <tr >
                <td >und&nbsp;Einstellservice </td>
                <td ></td>
                <td ><a target="_pk" href="https://www.priokom.de">www.priokom.de</a></td>
                <td @click="PopImpressum"><a href="#">Impressum</a></td>
            </tr>
            <tr class="mb">
                <td style="width:40%">*Festnetzpreis&nbsp;14&nbsp;ct/min;&nbsp;Mobilfunkpreise&nbsp;maximal&nbsp;42&nbsp;ct/min</td>
                <td >&nbsp;</td>
                <td @click="PopDatenschutz"><a href="#" >Datenschutz</a></td>
                <td ><a target="_si" href="ofl.txt">SIL Info</a></td>
            </tr>
        </table>
        <Modal v-model:visible="datenschutz" :okButton=safeButton  :title=titled :width=widthd>
            <div>
            <TheDataPrivacy/>
            </div>
        </Modal>
        <Modal v-model:visible="impressum" :okButton=safeButton :cancelButton=cancelButton :title=title :width=width>
            <div><TheImpressum/></div>
        </Modal>

    </footer>
</template>   

<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { ref } from "vue";
import { Modal } from "usemodal-vue3";
import { IsAdmin, IsViewer} from "@/store/functions/rights";
import { computed } from "vue";
import { useStore } from "vuex";
let userRights = ref()
const store = useStore();
const loggedIn = computed(()=> {return store.getters["auth/loggedIn"]});
if(loggedIn.value) {
  const tokendecoded = VueJwtDecode.decode(
    JSON.parse(localStorage.getItem("user")).access_token
  );
  userRights = tokendecoded.rights;
}
const admin = IsAdmin(userRights);
const viewer = IsViewer(userRights);
const safeButton = { text: "Ok" };
const cancelButton = { text: "Abbrechen" };
const impressum = ref(false);
const width = "90%";
const title = "";
function PopImpressum() {
  impressum.value = true;
}

const datenschutz = ref(false);
const widthd = "90%";
const titled = "Datenschutz";
function PopDatenschutz() {
  datenschutz.value = true;
}
</script>
<style scoped>
* {
  z-index: 99;
}
footer {
  height: 4.6vw;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0.75vw;
  background-color: #4b4b4b;
  color: white;
}

table {
  margin-left: 24px;
}
td {
  background-color: #4b4b4b;
  font-weight: lighter;
  font-size: 0.75vw;
  color: #e6e6e6d8;
  border-radius: 1px;
  text-decoration: none;
}
@media (max-width: 900px) {
  footer {
    height: 7.5vw;
  }
  td {
    font-size: 1.25vw;
  }
}
@media (max-width: 500px) {
  footer {
    height: 9vw;
  }
  td {
    font-size: 1.27vw;
  }
}
.ds {
  background-color: transparent;
  color: black;
  font-size: 100%;
  font-weight: bolder;
}

a {
  color: white;
}
</style>