import axios from 'axios';
import { AuthHeader } from './auth-header.js';

class Coredata {
    getAudio() {
        return axios({
            method: 'get',
            url: '/get-audio',
            responseType: 'blob',
            headers: {
                ...AuthHeader(),
                'cache-control': 'no-cache'
            }
        })
    }
    getAdress() {
        return axios({
            method: 'get',
            url: '/get-adress',
            headers: AuthHeader()
        })
    }
    deleteCdpn(cdpn) {
        return axios({
            method: 'delete',
            url: '/delete-cdpn',
            data: cdpn,
            headers: AuthHeader()
        })
    }
    newCdpn(cdpn) {
        return axios({
            method: 'post',
            url: '/new-cdpn',
            data: cdpn,
            headers: AuthHeader()
        })
    }
    setStandCdpn(cdpn) {
        return axios({
            method: 'put',
            url: '/set-stand-cdpn',
            data: cdpn,
            headers: AuthHeader()
        })
    }
    setPassword(password) {
        return axios({
            method: 'put',
            url: '/set-pw',
            data: password,
            headers: AuthHeader()
        })
    }

}
export default new Coredata();