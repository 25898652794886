function AuthHeader() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return {};
  }
}
function TFAuthHeader(hash) {
  let tfuser = JSON.parse(localStorage.getItem(`tfuser(${hash})`));
  if (tfuser) {
    return { Authorization: 'Bearer ' + tfuser };
  } else {
    return {};
  }
}
export { AuthHeader, TFAuthHeader }