import Authenticator from '../services/auth.service'

const user = JSON.parse(localStorage.getItem('user'));
const userState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null };

export default {
  namespaced: true,
  state: userState,
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    }
  },
  actions: {
    login({ commit }, user) {
      return Authenticator.Login(user).then(
        res => {
          if (res.data.access_token) {
            localStorage.setItem('user', JSON.stringify(res.data))
            commit('loginSuccessful', res.data);
          }
          return res.data

        }
      );
    },
    notFirstLogin({ commit }, user) {
      return Authenticator.NotFirstLogin(user).then(
        res => {
          if (res.data.access_token) {
            localStorage.setItem('user', JSON.stringify(res.data))
            commit('loginSuccessful', res.data);
          }
          return res.data

        }
      );
    },
    checkCode({ commit }, user) {
      return Authenticator.CheckCode(user.user).then(
        res => {
          if (res.data.tf_access_token) {
            localStorage.setItem(`tfuser(${user.hash})`, JSON.stringify(res.data.tf_access_token))
          }
          if (res.data.access_token) {
            localStorage.setItem('user', JSON.stringify(res.data))
            commit('loginSuccessful', res.data);
          }
          return res.data
        }
      );
    },
    FirstLogin({ commit }, user) {
      return Authenticator.FirstLogin(user).then(res => { return res });
    },
    logout({ commit }) {
      Authenticator.logout();
      commit('logout');
    },
  },
  mutations: {
    loginSuccessful(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
};