import { ref } from 'vue';
import successIcon from '@/assets/cross.png';
import infoIcon from '@/assets/cross_info.png';
import errorIcon from '@/assets/cross_error.png';
import errorSound from '@/assets/error_3.mp3';
export const notifications = ref([]);

export function notify(message, type) {
  const icons = {
    success: successIcon,
    info: infoIcon,
    error: errorIcon,
  };

  const notification = { message, icon: icons[type], id: Date.now() };
  notifications.value.push(notification);

  if (type === 'error') {
    const audio = new Audio(errorSound);
    audio.play()
  }

  // 5 seconds
  setTimeout(() => {
    notifications.value = notifications.value.filter(n => n.id !== notification.id);
  }, 5000);
}
