import axios from 'axios';
import { AuthHeader } from './auth-header.js';
class Schedule {
    get(filterDate) {
        return axios({
            method: 'get',
            url: `/get-schedule/${filterDate}`,
            data: filterDate,
            headers: AuthHeader()
        })
    }
    getView(filterDate) {
        return axios({
            method: 'get',
            url: `/get-schedule-view/${filterDate}`,
            data: filterDate,
            headers: AuthHeader()
        })
    }
}
export default new Schedule();