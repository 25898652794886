<template>
  <div>
    <div class="impressum">
      <h1>Impressum</h1>
      <h4>Angaben gemäß § 5 TMG</h4>
      <dl>
        <dd>PRIOKOM Informationstechnik GmbH</dd>
        <dd>Im Lehrer Feld 8</dd>
        <dd>89081 Ulm</dd>
        <dd></dd>
        <dd>Vertreten durch:</dd>
        <dd>Michael Palz</dd>
      </dl>

      <dl>
        <dd>Kontakt:</dd>
        <dd>Telefon: +49 731 / 96 29 40</dd>
        <dd>Telefax: +49 731 / 96 29 429</dd>
        <dd>E-Mail: info@priokom.de</dd>
      </dl>

      <dl>
        <dd>Registereintrag:</dd>
        <dd>Eintragung im Handelsregister.</dd>
        <dd>Registergericht: Amtsgericht Ulm</dd>
        <dd>Registernummer: HRB 3696</dd>
        <dd></dd>
        <dd>Umsatzsteuer-ID:</dd>
        <dd>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        </dd>
        <dd>DE197305820</dd>
      </dl>
      <h4>Inhaltlich verantwortlich gemäß § 55 RstV</h4>
      <dl>
        <dd>PRIOKOM Informationstechnik GmbH</dd>
        <dd>Im Lehrer Feld 8</dd>
        <dd>D-89081 Ulm</dd>
        <dd>&nbsp;</dd>
      </dl>
      <h4>Online-Streitbeilegung</h4>
      <p>
        Die Europäische Kommission stellt unter
        https://ec.europa.eu/consumers/odr/ eine Plattform zur
        Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer
        Streitigkeit nutzen können und auf der weitere Informationen zum Thema
        Streitschlichtung zu finden sind.
      </p>
      <h4>Außergerichtliche Streitbeilegung</h4>
      <p>
        Wir sind weder verpflichtet noch dazu bereit, im Falle einer
        Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  </div>
</template>  
<script setup>
</script>

<style scoped>
.impressum {
  color: #4b4b4b;
  font-size: 110%;
}
</style>
