<template>
  <div v-if="scheduleItem" class="edit">
    <h1>Dienst bearbeiten</h1>
    <div v-if="scheduleItem" class="schedule">
      <div>
        <label>Start: </label>
        <label v-if="!admin">{{ scheduleItem.startdate }}</label>
        <input
          v-if="admin"
          type="datetime-local"
          v-model="schedule.startdate"
        />
      </div>
      <div>
        <label>Ende: </label>
        <label v-if="!admin">{{ scheduleItem.enddate }}</label>
        <input v-if="admin" type="datetime-local" v-model="schedule.enddate" />
      </div>
      <div>
        <label>Arzt: </label>
        <label v-if="user && !ownschedule">{{ scheduleItem.callee }}</label>
        <select
          v-model="schedule.calleeid"
          v-if="manager || ownschedule || viewer"
          @change="GetCalleeData(false)"
        >
          <option
            v-for="item in schedule.callees"
            :key="item.calleeid"
            :value="item.calleeid"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div v-if="viewer">
        <label>Nachricht: </label>
        <input
          v-model="schedule.message"
          v-if="viewer"
          placeholder="Nachricht"
          />
      </div>
      <div v-if="(userId == schedule.calleeid || admin) && !viewer">
        <label>Nummer: </label>
        <select v-model="schedule.cdpn" @change="schedule.usepreset = false">
          <option v-for="item in schedule.cdpns" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="admin">
      <label>Mail senden: </label
      ><input type="checkbox" v-model="schedule.mail" />
    </div>
    <div v-if="(manager || userId == schedule.calleeid) && !viewer">
      <label>Voreinstellung nutzen: </label
      ><input type="checkbox" v-model="schedule.usepreset" />
    </div>
    <div v-if="schedule.preset.length != 0">
      <h2>Voreinstellung</h2>
      <div v-for="preset in schedule.preset" :key="preset.id">
        <label
          >Wochentag:
          {{ weekdays.find((weekday) => weekday.nr === preset.weekday).name }}
        </label>
        <label> Startzeit: {{ preset.starttime }} Uhr </label>
        <label>Endzeit: {{ preset.endtime }} Uhr </label>
        <label>Nummer: {{ preset.cdpn }} </label>
      </div>
    </div>
    <div class="buttons">
      <button @click="Safe" v-if="user && !ownschedule">Übernehmen</button>
      <button @click="Safe" v-if="(manager || ownschedule) && !viewer">
        Speichern
      </button>
      <button @click="SendRequest" v-if="viewer">Absenden</button>
      <button @click="emit('edit', 0)">Abbrechen</button>
      <button @click="Popup" v-if="admin">Löschen</button>
      <button
        @click="PopupSplitSchedule"
        v-if="(manager || ownschedule) && !viewer"
      >
        Teilen
      </button>
    </div>
  </div>
  <Modal
    v-model:visible="isvisible"
    :okButton="yesButton"
    :cancelButton="noButton"
    :title="title"
  >
    <div>Wollen Sie den Dienst wirklich löschen?</div>
  </Modal>
  <Modal
    v-model:visible="isvisiblesplitschedule"
    :okButton="yessplitschedule"
    :cancelButton="noButton"
    :title="titlesplitschedule"
  >
    <div>
      <div>
        <label>Teilzeit: </label
        ><input type="datetime-local" v-model="popup.splitdate" />
      </div>
      <div v-if="admin">
        <label>Arzt: </label
        ><select v-model="popup.calleeid" @change="GetCalleeData(true)">
          <option
            v-for="item in schedule.callees"
            :key="item.calleeid"
            :value="item.calleeid"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div>
        <label>Nummer: </label
        ><select v-model="popup.cdpn">
          <option v-for="item in schedule.cdpns" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
  </Modal>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsAdmin, IsUser, IsManager, IsViewer } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";

//variables
//vue
const props = defineProps({ scheduleid: Number });
const emit = defineEmits(["edit"]);
const store = useStore();
const router = useRouter();
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userId = tokendecoded.calleeid;
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
const manager = IsManager(userRights);
const user = IsUser(userRights);
const viewer = IsViewer(userRights);
const ownschedule = ref(false);
//scheduleitem
const schedule = reactive({
  calleeid: "",
  cdpn: "",
  cdpns: [],
  callees: [],
  preset: [],
  startdate: null,
  enddate: null,
  usepreset: null,
  weekday: null,
  message: null,
  mail: true
});
const weekdays = [
  { nr: 0, name: "Montag" },
  { nr: 1, name: "Dienstag" },
  { nr: 2, name: "Mittwoch" },
  { nr: 3, name: "Donnerstag" },
  { nr: 4, name: "Freitag" },
  { nr: 5, name: "Samstag" },
  { nr: 6, name: "Sonntag" },
];
let method = "edit/getItem";
let scheduleItem = ref();
//popup
const currentdate = new Date();
const timezonems = currentdate.getTimezoneOffset() * 60000;
const localdate = new Date(currentdate.getTime() - timezonems);
const localformatteddate = localdate.toISOString().slice(0, 16);
const popup = reactive({
  calleeid: "",
  cdpn: "",
  splitdate: null,
});

//init
function ConvertDate(rawdate) {
  rawdate = rawdate.split(" ");
  let time = rawdate[2];
  rawdate = rawdate[1].split(".");
  let date = `${rawdate[2]}-${rawdate[1]}-${rawdate[0]}T${time}`;
  return date;
}
if (admin) {
  method = "admin/getScheduleItem";
  scheduleItem = computed(() => {
    return store.getters["admin/ScheduleItem"];
  });
  schedule.preset = computed(() => {
    return store.getters["admin/CalleePreset"];
  });
  schedule.callees = computed(() => {
    return store.getters["admin/Callees"];
  });
  schedule.cdpns = computed(() => {
    return store.getters["admin/Cdpns"];
  });
} else if (user || manager) {
  scheduleItem = computed(() => {
    return store.getters["edit/scheduleItem"];
  });
  schedule.callees = computed(() => {
    return store.getters["edit/Callees"];
  });
  schedule.cdpns = computed(() => {
    return store.getters["edit/Cdpns"];
  });
  store.dispatch("edit/getCdpns").catch((err) => OnError(err, router));
  store.dispatch("edit/getCallees").catch((err) => OnError(err, router));
} else {
  scheduleItem = computed(() => {
    return store.getters["edit/scheduleItem"];
  });
  schedule.callees = computed(() => {
    return store.getters["edit/Callees"];
  });
  store.dispatch("edit/getCallees").catch((err) => OnError(err, router));
}
store
  .dispatch(method, props.scheduleid)
  .then((res) => {
    schedule.startdate = ConvertDate(res.startdate);
    schedule.enddate = ConvertDate(res.enddate);
    schedule.weekday = new Date(ConvertDate(res.enddate)).getDay() - 1;
    schedule.calleeid = res.calleeid;
    schedule.cdpn = res.cdpn;
    schedule.usepreset = res.usepreset;
    popup.splitdate = ConvertDate(res.startdate);
    popup.calleeid = res.calleeid;
    popup.cdpn = res.cdpn;
    ownschedule.value = userId == res.calleeid ? true : false;
  })
  .catch((err) => OnError(err, router));

//functions
function GetCalleeData(inpopup) {
  if (admin) {
    let id = schedule.calleeid;
    if (inpopup) {
      id = popup.calleeid;
    } else {
      
      schedule.usepreset = true;
      store
        .dispatch("admin/getCalleePreset", id)
        .catch((err) => {
          store.commit("admin/setCalleePreset", {preset:[]});
          schedule.usepreset = false
          OnError(err, router)
        });
    }
    store
      .dispatch("admin/getCdpns", { calleeid: id })
      .then((res) => {
        popup.cdpn = res.cdpns[0];
        schedule.cdpn = res.cdpns[0];
      })
      .catch((err) => OnError(err, router));
  }
}
function Safe() {
  if (viewer) return;
  if (admin) {
    let scheduleitem = {
      scheduleid: props.scheduleid,
      calleeid: schedule.calleeid,
      cdpn: schedule.cdpn,
      startdate: schedule.startdate, 
      enddate: schedule.enddate,
      usepreset: schedule.usepreset,
      mail: schedule.mail
    };
    store
      .dispatch("admin/setScheduleItem", scheduleitem)
      .then((res) => {
        notify(res.data.detail, "success");
        emit("edit", 0);
      })
      .catch((err) => OnError(err, router));
  } else if (schedule.cdpn && schedule.calleeid == userId) {
    store
      .dispatch("edit/setCdpn", {
        scheduleid: props.scheduleid,
        cdpn: schedule.cdpn,
        usepreset: schedule.usepreset,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        emit("edit", 0);
      })
      .catch((err) => OnError(err, router));
  } else {
    store
      .dispatch("edit/setCallee", {
        scheduleid: props.scheduleid,
        calleeid: ownschedule.value == true ? schedule.calleeid : userId,
        usepreset: schedule.usepreset,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        emit("edit", 0);
      })
      .catch((err) => OnError(err, router));
  }
}

function SendRequest() {
  store
    .dispatch("edit/sendRequest", {
      scheduleid: props.scheduleid,
      newcallee: schedule.calleeid,
      message: schedule.message
    })
    .then((res) => {
      notify(res.data.detail, "success");
      emit("edit", 0);
    })
    .catch((err) => OnError(err, router));
}

//Popups
// Popup DeleteSchedule
function DeleteSchedule() {
  store
    .dispatch("admin/deleteSchedule", props.scheduleid)
    .then((res) => {
      notify(res.data.detail, "success");
      emit("edit", 0);
    })
    .catch((err) => OnError(err, router));
  isvisible.value = false;
}
let isvisible = ref(false);
const title = "Dienst löschen?";
const yesButton = { text: "Ja", onclick: DeleteSchedule };
const noButton = { text: "Nein", onclick: null };
function Popup() {
  isvisible.value = true;
}

// Popup SplitSchedule
function SplitSchedule() {
  if (viewer) return;
  if (
    schedule.startdate > popup.splitdate ||
    popup.splitdate > schedule.enddate
  ) {
    notify("Teilzeit liegt nicht dazwischen", "error");
    return;
  }
  if (admin) {
    let scheduleitem = {
      scheduleid: props.scheduleid,
      calleeid: schedule.calleeid,
      cdpn: "",
      startdate: schedule.startdate,
      enddate: popup.splitdate,
      usepreset: 0,
    };
    let newschedule = {
      calleeid: popup.calleeid,
      cdpn: popup.cdpn,
      startdate: popup.splitdate,
      enddate: schedule.enddate,
      scheduleid: 0,
      usepreset: 0,
    };
    store
      .dispatch("admin/splitSchedule", {
        scheduleitem: scheduleitem,
        newschedule: newschedule,
      })
      .then((res) => {
        notify(res.data.detail, "success");
        emit("edit", 0);
        isvisiblesplitschedule.value = false;
      })
      .catch((err) => OnError(err, router));
  } else {
    let newschedule = {
      scheduleid: props.scheduleid,
      splittime: popup.splitdate,
      cdpn: popup.cdpn,
      usepreset: 0,
    };
    store
      .dispatch("edit/splitSchedule", newschedule)
      .then((res) => {
        notify(res.data.detail, "success");
        emit("edit", 0);
        isvisiblesplitschedule.value = false;
      })
      .catch((err) => OnError(err, router));
  }
}
let isvisiblesplitschedule = ref(false);
const titlesplitschedule = "Dienst teilen?";
const yessplitschedule = { text: "Ja", onclick: SplitSchedule };
function PopupSplitSchedule() {
  popup.splitdate = schedule.startdate <= localformatteddate && schedule.enddate >= localformatteddate ? localformatteddate : schedule.startdate;
  isvisiblesplitschedule.value = true;
}
</script>

<style scoped>
.schedule div {
  margin: 1px;
  display: grid;
}
.schedule {
  display: inline-flex;
}
.edit {
  display: grid;
}
.buttons {
  margin-top: 10px;
  display: inline-flex;
}
select {
  width: 100%;
}
@media (max-width: 800px) {
  .schedule {
    display: table;
  }
}
@media (max-width: 500px) {
  .schedule {
    display: block;
  }
  .buttons {
    display: grid;
  }
  .buttons button {
    margin: 1px;
  }
}
</style>