<template>
  <div class="home">
    <TheHeader />
    <nav>
      <router-link to="/home" activeClass="active">Dienstplan</router-link>
      <router-link to="/coredata">Stammdaten</router-link>
      <button @click="logout">Logout</button>
    </nav>

    <router-view></router-view>

    <TheFooter />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
const store = useStore();
const router = useRouter();
function logout() {
  store.dispatch("auth/logout").then(router.push("/"));
}
</script>
<style scoped>
.home {
  margin-bottom: 4.6vw;
  height: 90%;
}
</style>
