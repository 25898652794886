<template lang="">

    <div v-if="logs&&admin">
        <select v-model="searchValue">
            <option value="">Alle</option>
            <option v-for="location in locations" :value="location.wherein" :key="location.wherein">{{location.wherein}}</option>
        </select>
        <select v-model="filterRegion">
        <option value="0">Alle</option>
        <option
          v-for="region in regions"
          :value="region.regionid"
          :key="region.regionid"
        >
          {{ region.name }}
        </option>
      </select>
        <input v-if="searchValue=='setschedule'" type="date" v-model="filterdate" @blur="FilterLog" class="datepicker">
        <button v-if="logs" @click="downloadCSV(logs, 'Log')">Download</button>
        <EasyDataTable
        :search-field="searchField"
        :search-value="searchValue"
        :headers="headers"
        :items="logs"
        @click-row="Recover"
  />
    </div>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsAdmin } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";
import { downloadCSV } from "@/store/functions/downloadCSV";

//variables
//vue
const props = defineProps({ calleeid: Number });
const emit = defineEmits(["edit"]);
const store = useStore();
const router = useRouter();
let filterRegion = ref(store.getters["admin/RegionId"]);
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
//filter
let searchValue = ref("");
let filterWherein = ref("");
let locations = ref("");
let filterdate = ref();
//table
const logs = computed(() => {
  return store.getters["admin/Log"];
});
const regions = computed(() => {
  return store.getters["admin/Regions"];
});
const searchField = ["wherein"];
const headers = [
  { text: "Wann", value: "timestamp" },
  { text: "Wo", value: "wherein" },
  { text: "Wer", value: "who" },
  { text: "Was", value: "what" },
];

//init
function FilterDuplicates(array) {
  let uniquearray = array.filter((item, index) => {
    return index === array.findIndex((array) => array.wherein === item.wherein);
  });
  return uniquearray;
}
if (admin) {
  store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
  if (filterRegion.value!=0)
    store
      .dispatch("admin/getLogRegion", filterRegion.value)
      .then((res) => {
        locations.value = FilterDuplicates(res.log);
      })
      .catch((err) => OnError(err, router));
  else
    store
      .dispatch("admin/getLog")
      .then((res) => {
        locations.value = FilterDuplicates(res.log);
      })
      .catch((err) => OnError(err, router));
}
//functions
function FilterLog() {
  if (!filterdate.value) return;
  store
    .dispatch("admin/getLogSchedule", {
      filterdate: filterdate.value,
      regionid: filterRegion.value,
    })
    .catch((err) => OnError(err, router));
}
function FilterRegion() {
  store
    .dispatch("admin/getLogRegion", filterRegion.value)
    .catch((err) => OnError(err, router));
}
function Recover(row) {
  var jsonobj = JSON.parse(row.what);
  var id = 0;
  if (Object.prototype.hasOwnProperty.call(jsonobj, "calleeid")) {
    id = jsonobj.calleeid;
  } else if (Object.prototype.hasOwnProperty.call(jsonobj, "regionid")) {
    id = jsonobj.regionid;
  }
  if (
    searchValue.value == "deletecallee" ||
    searchValue.value == "deleteregion"
  ) {
    store
      .dispatch("admin/recover", { what: searchValue.value, id: id })
      .then((res) => notify(res.data.detail, "success"))
      .catch((err) => OnError(err, router));
  }
}
watch(searchValue, (newvalue, oldvalue) => {
  if (newvalue == "setschedule") return;
  if (admin) {
    store
      .dispatch("admin/getLog")
      .then((res) => {
        locations.value = FilterDuplicates(res.log);
      })
      .catch((err) => OnError(err, router));
  }
});
watch(filterRegion, (newvalue, oldvalue) => {
  if (admin) {
    if (filterRegion.value == 0)
      store
        .dispatch("admin/getLog")
        .then((res) => {
          locations.value = FilterDuplicates(res.log);
        })
        .catch((err) => OnError(err, router));
    else
      store
        .dispatch("admin/getLogRegion", filterRegion.value)
        .then((res) => {
          locations.value = FilterDuplicates(res.log);
        })
        .catch((err) => OnError(err, router));
  }
});
</script>
<style scoped>
select {
  margin: 2px;
}
</style>