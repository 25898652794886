<template lang="">
    <div>
        <keep-alive>
        <TheAdminCoredata v-if="!togglecomp" :call="state.call" @update="Update" @edit="Edit" />
        </keep-alive>
        <TheAdminCoredataEdit v-if="togglecomp" :calleeid="calleeid" @edit="Edit"/>
    </div>
</template>
<script setup>
import TheAdminCoredata from "../../components/TheAdminCoredata.vue";
import TheAdminCoredataEdit from "../../components/TheAdminCoredataEdit.vue";
import { ref, reactive } from "vue";

let togglecomp = ref(false);
let calleeid = ref(0);
let state = reactive({ call: false });
function Update() {
  state.call = false;
}
function Edit(cid) {
  if (cid === 0) {
    togglecomp.value = false;
    state.call = true;
  } else {
    calleeid.value = cid;
    togglecomp.value = true;
  }
}
</script>
<style lang="">
</style>