import axios from 'axios';
import { AuthHeader } from './auth-header.js';

class Admin {
    getCdpns(calleeid) {
        return axios.get(`admin/get-cdpns/${calleeid}`, { headers: AuthHeader() })
    }
    getCallees(region) {
        return axios.get(`admin/get-callees/${region}`, { headers: AuthHeader() })
    }
    getSearchedCallees(cname) {
        return axios.get(`admin/get-searched-callees/${cname}`, { headers: AuthHeader() })
    }
    getCalleeInf(calleeid) {
        return axios({
            method: 'get',
            url: `admin/get-callee-information/${calleeid}`,
            data: calleeid,
            headers: AuthHeader()
        })
    }
    getCalleePreset(calleeid) {
        return axios({
            method: 'get',
            url: `admin/get-callee-preset/${calleeid}`,
            data: calleeid,
            headers: AuthHeader()
        })
    }
    getCalleeAudio(calleeid) {
        return axios({
            method: 'get',
            url: `admin/get-callee-audio/${calleeid}`,
            data: calleeid,
            responseType: 'blob',
            headers: {
                ...AuthHeader(),
                'cache-control': 'no-cache'
            }
        })
    }
    getCalleeAudioNoConn(calleeid) {
        return axios({
            method: 'get',
            url: `admin/get-callee-audio-no-connect/${calleeid}`,
            data: calleeid,
            responseType: 'blob',
            headers: {
                ...AuthHeader(),
                'cache-control': 'no-cache'
            }
        })
    }
    getRegions() {
        return axios({
            method: 'get',
            url: 'admin/get-regions',
            headers: AuthHeader()
        })
    }
    getRegionInf(regionid) {
        return axios({
            method: 'get',
            url: `admin/get-region-information/${regionid}`,
            data: regionid,
            headers: AuthHeader()
        })
    }
    getRegionAudio(regionid) {
        const config = {
            responseType: 'arraybuffer',
            headers: {
                ...AuthHeader(),
                'cache-control': 'no-cache'
            }
        }
        const urls = [`admin/get-region-audio/${regionid}`, `admin/get-region-audio-no-duty-1/${regionid}`, `admin/get-region-audio-no-duty-2/${regionid}`, `admin/get-region-audio-reminder/${regionid}`, `admin/get-region-audio-no-connect/${regionid}`]
        const req = urls.map((url) => axios.get(url, config))
        return axios.all(req).then(axios.spread((...responses) => {
            return responses.map(response => response);
        }))
    }
    getRegionTTS() {
        return axios({
            method: 'get',
            url: `admin/get-region-tts`,
            headers: AuthHeader()
        })
    }
    getSchedule(filterDate, region) {
        return axios({
            method: 'get',
            url: `/admin/get-schedule/${region}/${filterDate}`,
            data: region, filterDate,
            headers: AuthHeader()
        })
    }
    getScheduleItem(scheduleid) {
        return axios.get(`admin/get-schedule-item/${scheduleid}`, { headers: AuthHeader() })
    }
    getLog() {
        return axios({
            method: 'get',
            url: `admin/get-log`,
            headers: AuthHeader()
        })
    }
    getLogSchedule(filterdate, regionid) {
        return axios({
            method: 'get',
            url: `admin/get-log-schedule/${filterdate}/${regionid}`,
            headers: AuthHeader()
        })
    }
    getLogRegion(regionid) {
        return axios({
            method: 'get',
            url: `admin/get-log-region/${regionid}`,
            headers: AuthHeader()
        })
    }
    getCalllist(filterdate, regionid) {
        return axios({
            method: 'get',
            url: `admin/get-calllist/${filterdate}/${regionid}`,
            headers: AuthHeader()
        })
    }
    getScheduleEnds() {
        return axios({
            method: 'get',
            url: `admin/get-schedule-ends`,
            headers: AuthHeader(),
            responseType: 'blob',
        })
    }
    setCalleePw(callee) {
        return axios({
            method: 'put',
            url: 'admin/set-callee-pw',
            data: callee,
            headers: AuthHeader()
        })
    }
    setCalleeInf(calleeinf) {
        return axios({
            method: 'put',
            url: 'admin/set-callee-information',
            data: calleeinf,
            headers: AuthHeader()
        })
    }
    setCalleePreset(calleepreset) {
        return axios({
            method: 'put',
            url: 'admin/set-callee-preset',
            data: calleepreset,
            headers: AuthHeader()
        })
    }
    setRegionInf(regioninf) {
        return axios({
            method: 'put',
            url: 'admin/set-region-information',
            data: regioninf,
            headers: AuthHeader()
        })
    }
    setScheduleItem(scheduleitem) {
        return axios({
            method: 'put',
            url: 'admin/set-schedule-item',
            data: scheduleitem,
            headers: AuthHeader()
        })
    }
    setStandCdpn(callee) {
        return axios({
            method: 'put',
            url: 'admin/set-stand-cdpn',
            data: callee,
            headers: AuthHeader()
        })
    }
    setReplCdpn(callee) {
        return axios({
            method: 'put',
            url: 'admin/set-repl-cdpn',
            data: callee,
            headers: AuthHeader()
        })
    }
    newSchedule(file) {
        return axios({
            method: 'post',
            url: 'admin/upload-schedule',
            data: file,
            headers: AuthHeader(), 'Content-Type': 'multipart/form-data'
        })
    }
    newScheduleItem(schedule) {
        return axios({
            method: 'post',
            url: 'admin/new-schedule',
            data: schedule,
            headers: AuthHeader()
        })
    }
    newCdpn(callee) {
        return axios({
            method: 'post',
            url: 'admin/new-cdpn',
            data: callee,
            headers: AuthHeader()
        })
    }
    newCallee(callee) {
        return axios({
            method: 'post',
            url: 'admin/new-callee',
            data: callee,
            headers: AuthHeader()
        })
    }
    newRegion(region) {
        return axios({
            method: 'post',
            url: 'admin/new-region',
            data: region,
            headers: AuthHeader()
        })
    }
    deleteCdpn(cdpn) {
        return axios({
            method: 'delete',
            url: 'admin/delete-cdpn',
            data: cdpn,
            headers: AuthHeader()
        })
    }
    deleteCallee(calleeid) {
        return axios({
            method: 'delete',
            url: `admin/delete-callee/${calleeid}`,
            data: calleeid,
            headers: AuthHeader()
        })
    }
    deleteRegion(regionid) {
        return axios({
            method: 'delete',
            url: `admin/delete-region/${regionid}`,
            data: regionid,
            headers: AuthHeader()
        })
    }
    deleteSchedule(scheduleid) {
        return axios({
            method: 'delete',
            url: `admin/delete-schedule/${scheduleid}`,
            data: scheduleid,
            headers: AuthHeader()
        })
    }
    recover(what, id) {
        return axios({
            method: 'put',
            url: `admin/recover/${what}/${id}`,
            headers: AuthHeader()
        })
    }
}
export default new Admin();