<template>
  <div>
    <h1>Stammdaten</h1>
    <div class="coredata">
      <div>
        <label
          >Neues Passwort vergeben(muss Zahlen und Groß-/Kleinbuchstaben
          enthalten mind. 10 Zeichen):
        </label>
        <input
          type="password"
          v-model="password"
          placeholder="Neues Passwort"
        />
        <input
          type="password"
          v-if="password"
          v-model="password2"
          placeholder="Passwort bestätigen"
        />
      </div>
      <div>
        <label>Neue Nummer hinzufügen: </label>
        <input v-model="new_cdpn" placeholder="Neue Nummer" />
      </div>
      <div>
        <label
          >Standardrufnummer(zukünftige Dienste werden aktualisiert):
        </label>
        <select v-model="def_cdpn" @change="updated = true">
          <option v-for="item in cdpns" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
        <label><br />Adresse: {{ adress }}</label>
      </div>
      <div>
        <label>Ansage: </label>
        <audio v-if="audiourl" controls>
          <source :src="audiourl" type="audio/mpeg" />
        </audio>
      </div>
      <div>
        <button @click="Safe">Speichern</button>
      </div>
      <br /><br />
      <div>
        <label>Nummer löschen: </label>
        <select v-model="del_cdpn">
          <option disabled value="">Nummer löschen</option>
          <option v-for="item in cdpns" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
        <button @click="DelCdpn">Löschen</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { OnError } from "@/store/functions/errorHandler";
import { notify } from "@/store/functions/notifications";

//variables
//vue
const store = useStore();
const router = useRouter();
//calleeinf
let password = ref("");
let password2 = ref("");
let del_cdpn = ref("");
let def_cdpn = ref("");
let new_cdpn = ref("");
let updated = ref(false);
let audiourl = ref();
let adress = ref();
const cdpns = computed(() => {
  return store.getters["coredata/Cdpns"];
});

//init
store
  .dispatch("coredata/getCdpns")
  .then((res) => (def_cdpn.value = res.cdpns[0]))
  .catch((err) => OnError(err, router));
store
  .dispatch("coredata/getAdress")
  .then((res) => {
    adress.value = res.adress;
  })
  .catch((err) => OnError(err, router));
store
  .dispatch("coredata/getAudio")
  .then((res) => {
    const blob = new Blob([res], { type: "audio/mpeg" });
    audiourl.value = URL.createObjectURL(blob);
  })
  .catch((err) => {
    console.log(err);
    notify("Ansage nicht vorhanden", "error");
  });

//functions
//deleteCdpn
function DelCdpn() {
  if (del_cdpn.value) {
    store
      .dispatch("coredata/deleteCdpn", { cdpn: del_cdpn.value })
      .then((res) => {
        notify(res.data.detail, "success");
        del_cdpn.value = "";
      })
      .catch((err) => OnError(err, router));
  }
}
//safe
function Safe() {
  if (def_cdpn.value && updated.value) {
    store
      .dispatch("coredata/setStandCdpn", { cdpn: def_cdpn.value })
      .then((res) => notify(res.data.detail, "success"))
      .catch((err) => OnError(err, router));
  } else if (new_cdpn.value) {
    store
      .dispatch("coredata/newCdpn", { cdpn: new_cdpn.value })
      .then((res) => {
        notify(res.data.detail, "success");
        new_cdpn.value = "";
      })
      .catch((err) => OnError(err, router));
  } else if (password.value) {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
    if (password.value == password2.value) {
      if (re.test(password.value)) {
        store
          .dispatch("coredata/setPassword", { password: password.value })
          .then(() => {
            password.value = "";
            password2.value = "";
            notify("Passwort wurde geändert", "success");
          })
          .catch((err) => OnError(err, router));
      } else {
        notify("Passwort wiederspricht Anforderungen", "error");
      }
    } else {
      notify("Passwort ist nicht gleich", "error");
    }
  }
  updated.value = false;
}
</script>
<style scoped>
.coredata {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
.coredata div {
  margin: 5px;
}
audio {
  width: 30%;
  justify-content: top;
}
button {
  margin: 0;
}
@media (max-width: 600px) {
  select {
    width: 100%;
  }
  audio {
    width: 100%;
  }
  div {
    display: grid;
  }
}
</style>