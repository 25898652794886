import { notify } from "./notifications";
function OnError(err, router) {
    if (err.response.status && err.response.status == 401) {
        notify(err.response.data.detail, 'info');
        router.push("/");
    } else if (err.response.status && err.response.status == 422) {
        const detail = err.response.data.detail;
        let fields = [];
        let msg = "";
        detail.forEach(item => {
            fields.push(item.loc.slice(1).join('.') + " : " + item.msg);

        });
        notify(fields.join(', '), 'error');
    } else if (err.code == "ERR_NETWORK") {
        notify("Server nicht gefunde", 'error');
    } else if (err.response.data.detail) {
        notify(err.response.data.detail, 'error');
    } else {
        notify("Something went wrong ...", 'error')
        console.log(err)
    }
}
export { OnError }