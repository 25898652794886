import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { Notification } from 'notiwind'
import 'vue3-easy-data-table/dist/style.css';
import './assets/main.css';
import TheLoadingAnimation from './components/TheLoadingAnimation.vue'
import TheImpressum from './components/TheImpressum.vue'
import TheDataPrivacy from './components/TheDataPrivacy.vue'
import TheNotification from './components/TheNotification.vue'

axios.defaults.withCredentials = false
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
const app=createApp(App)
app.use(router)
app.use(store)
app.use(Notification)
app.component('EasyDataTable', Vue3EasyDataTable)
app.component('TheNotification', TheNotification)
app.component('TheLoadingAnimation', TheLoadingAnimation)
app.component('TheImpressum', TheImpressum)
app.component('TheDataPrivacy', TheDataPrivacy)
app.mount('#app')
app.config.errorHandler = (err) => { console.log(err); };
