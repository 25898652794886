<template>
  <div class="admin">
    <TheHeader />
    <nav>
      <router-link to="/admin" activeClass="active">Dienstplan</router-link>
      <router-link to="/admincoredata">Arztdaten</router-link>
      <router-link to="/adminlog">Log</router-link>
      <router-link to="/admincalllist">Anrufliste</router-link>
      <button @click="logout">Logout</button>
    </nav>
    <router-view></router-view>
  </div>
  <TheFooter />
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
const store = useStore();
const router = useRouter();
function logout() {
  store.dispatch("auth/logout").then(router.push("/"));
}
</script>
<style scoped>
.admin {
  margin-bottom: 4.6vw;
}
button:hover {
  background-color: #91bd2a;
}
</style>
