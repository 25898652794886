<template>
  <div>
    <keep-alive>
      <TheSchedule
        v-if="!togglecomp"
        :call="state.call"
        @update="Update"
        @edit="Edit"
      />
    </keep-alive>
    <TheScheduleEdit v-if="togglecomp" :scheduleid="scheduleid" @edit="Edit" />
  </div>
</template>
  
  <script setup>
import TheScheduleEdit from "../../components/TheScheduleEdit.vue";
import TheSchedule from "../../components/TheSchedule.vue";
import { ref, reactive } from "vue";

let togglecomp = ref(false);
let scheduleid = ref(0);
const state = reactive({ call: false });

function Update() {
  state.call = false;
}

function Edit(schedule) {
  scheduleid.value = schedule;
  togglecomp.value = !togglecomp.value;
  if (!togglecomp.value) state.call = true;
}
</script>
  
  <style>
</style>
  