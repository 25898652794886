import axios from 'axios'
import { TFAuthHeader } from './auth-header.js';

class Authenticator {
    Login(user) {
        return axios({
            method: 'post',
            url: 'login',
            data: user,
        })
    }
    NotFirstLogin(user) {
        return axios({
            method: 'post',
            url: 'not-first-login',
            data: user.user,
            headers: TFAuthHeader(user.hash)
        })
    }
    CheckCode(user) {
        return axios({
            method: 'post',
            url: 'check-code',
            data: user,
        })
    }
    logout() {
        localStorage.removeItem('user')
    }
    FirstLogin(user) {
        return axios({
            method: 'put',
            url: 'first-login',
            data: user,
        })
    }
}
export default new Authenticator();