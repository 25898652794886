import axios from 'axios';
import { AuthHeader } from './auth-header.js';

class Edit {
    getItem(scheduleid) {
        return axios.get(`get-schedule-item/${scheduleid}`, { headers: AuthHeader() })
    }
    getCallees() {
        return axios.get('get-callees', { headers: AuthHeader() })
    }
    getCdpns() {
        return axios.get('get-cdpns', { headers: AuthHeader() })
    }
    setCallee(schedule) {
        return axios({
            method: 'put',
            url: 'set-callee',
            data: schedule,
            headers: AuthHeader()
        })
    }
    setCdpn(schedule) {
        return axios({
            method: 'put',
            url: 'set-cdpn',
            data: schedule,
            headers: AuthHeader()
        })
    }
    splitSchedule(schedule) {
        return axios({
            method: 'post',
            url: 'split-schedule',
            data: schedule,
            headers: AuthHeader()
        })
    }
    sendRequest(schedule) {
        return axios({
            method: 'post',
            url: 'service-request',
            data: schedule,
            headers: AuthHeader()
        })
    }

}
export default new Edit();