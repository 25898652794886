<template>
  <div class="loading">
    <div class="loading-spinner"></div>
  </div>
</template>  
<script setup>
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-top: 5px;
  padding-left: 20px;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #91bd2a;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
