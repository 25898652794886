function formatLocalDate() {
    const currentdate = new Date();
    const timezonems = currentdate.getTimezoneOffset() * 60000;
    const localdate = new Date(currentdate.getTime() - timezonems);

    const year = localdate.getFullYear();
    const month = String(localdate.getMonth() + 1).padStart(2, '0');
    const day = String(localdate.getDate()).padStart(2, '0');
    const hours = String(localdate.getHours()).padStart(2, '0');
    const minutes = String(localdate.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function convertToCSV(data) {
    const translatedheaders = {
        "cdpn":"Nummer", 
        "callee":"Name", 
        "startdate":"Startdatum", 
        "enddate":"Enddatum", 
        "rname":"Regionsname", 
        "caller":"Anrufer", 
        "duration":"Dauer",
        "timestamp": "Wann wurde es gemacht",
        "wherein": "In welchem Bereich",
        "what": "Was wurde gemacht",
        "who": "Wer hat es gemacht",
        "calleeid": "Benutzer Id",
        "name": "Name",
        "username": "Benutzername",
    };
    const headers = Object.keys(translatedheaders).filter(header => Object.prototype.hasOwnProperty.call(data[0], header));
    const headerRow = headers.map(header => translatedheaders[header]).join(';') + '\n';
    const csv = data.map(row => {
        return headers.map(header => {
            const value = row[header];
            return isNaN(value) ? value : `Nr: ${value}`; 
        }).join(';');
    }).join('\n');
    return headerRow + csv;
}




async function downloadCSV(data, filename) {
    if (!data || data.length === 0) {
        console.error("Der Datensatz ist leer oder undefiniert.");
        return '';
    }
    const csvContent = convertToCSV(data);
    const blob = new Blob(["\ufeff"+csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${await formatLocalDate()}_${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
export {downloadCSV}