<template lang="">

    <div v-if="regions&&admin">
        <select v-model="filterRegion">
        <option value="0">Alle</option>
        <option
          v-for="region in regions"
          :value="region.regionid"
          :key="region.regionid"
        >
          {{ region.name }}
        </option>
      </select>
        <input type="date" v-model="filterdate" @blur="FilterCalllist" class="datepicker">
        <input
        v-model="searchValue"
        placeholder="Arzt"
      />
      <button v-if="calllist" @click="downloadCSV(calllist,'Anruflist')">Download</button>
    </div> 
    <div v-if="calllist">
        <EasyDataTable
        :search-field="searchField"
        :search-value="searchValue"
        :headers="headers"
        :items="calllist"
  />
    </div>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsAdmin } from "@/store/functions/rights";
import { downloadCSV } from "@/store/functions/downloadCSV";
import { notify } from "@/store/functions/notifications";

//variables
//vue
const props = defineProps({ calleeid: Number });
const emit = defineEmits(["edit"]);
const store = useStore();
const router = useRouter();
let filterRegion = ref(store.getters["admin/RegionId"]);
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
//filter
let searchValue = ref();
const currentdate = new Date();
const timezonems = currentdate.getTimezoneOffset() * 60000;
const localdate = new Date(currentdate.getTime() - timezonems);
const formatteddate = localdate.toISOString().slice(0, 10);
let filterdate = ref(formatteddate);
//table
const calllist = computed(() => {
  return store.getters["admin/Calllist"];
});
const regions = computed(() => {
  return store.getters["admin/Regions"];
});
const searchField = ["name"];
const headers = [
  { text: "Datum", value: "startdate" },
  { text: "Region", value: "rname" },
  { text: "Arzt", value: "name" },
  { text: "Nummer", value: "cdpn" },
  { text: "Anrufer", value: "caller"},
  { text: "Dauer", value:"duration"}
];

//init
function FilterDuplicates(array) {
  let uniquearray = array.filter((item, index) => {
    return index === array.findIndex((array) => array.wherein === item.wherein);
  });
  return uniquearray;
}
if (admin) {
  store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
    store
      .dispatch("admin/getCalllist", {
      filterdate: filterdate.value,
      regionid: filterRegion.value,
    })
      .catch((err) => OnError(err, router));

}
//functions
function FilterCalllist() {
  if (!filterdate.value) return;
  if (!filterRegion.value) filterRegion.value = 0;
  store
    .dispatch("admin/getCalllist", {
    filterdate: filterdate.value,
    regionid: filterRegion.value,
    })
    .catch((err) => OnError(err, router));
    searchValue.value = ""
}
watch(filterRegion, (newvalue, oldvalue) => {
  if (admin) {
    store.commit("admin/setRegionId", newvalue);
      store
        .dispatch("admin/getCalllist", {
        filterdate: filterdate.value,
        regionid: filterRegion.value,
    })
    .catch((err) => OnError(err, router));
    searchValue.value = ""
  }
});
</script>
<style scoped>
select {
  margin: 2px;
}
</style>