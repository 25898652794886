<template lang="">
    <div class="container">
        <div class="cross"><img class="cross" src="../assets/cross.png"></div>
        <div class="text"><span>Ärztliche Bereitschaftsdienste</span></div>
        <div class="logo"><a target="_blankpriokom" href="https://www.priokom.de"><img src="../assets/priokom-logo-neu.png"></a></div>
    </div>
</template>
<script setup>
</script> 
<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
}
.cross {
  flex: 0.1;
  padding-left: 12px;
}
.text {
  flex: 0.8;
  text-align: center;
  font-size: 3.3dvw;
}
.logo {
  flex: 0.1;
}
.logo img {
  height: 5dvw;
  width: auto;
}
</style>