<template lang="">
  <div>
    <TheHeader/>
    <div class="login">
      <label>Benutzername: </label>
      <input v-on:keyup.enter="submit" v-model="loginData.username" type="text" placeholder="Benutzername" /><br>
      <label>Passwort: </label>
      <input v-on:keyup.enter="submit" v-model="loginData.password" type="password" id="password" placeholder="Passwort" /><br>
      <label v-if="twofactor">Code: </label>
      <input v-if="twofactor" v-on:keyup.enter="submit" v-model="loginData.code" placeholder="Code" /><br>
      <TheLoadingAnimation v-if="loading" />
      <button @click="submit" v-if="!loading">Login</button>
    </div>
    <!--When the boss says that they can do something
    <a @click="firstLogin">Erste Anmeldung</a>
    <div class="firstlogin" v-if="firstLoginData.firstLogin">
      <div>
        <h1>Alte Logindaten:</h1>
        <label>Benutzername: </label>
        <input type="text" placeholder="Benutzername" v-model="firstLoginData.oldUsername">
        <label>Passwort: </label>
        <input type="password" id="password" placeholder="Passwort" v-model="firstLoginData.oldPassword">
      </div>
      <div>
        <label>Neues Passwort vergeben(muss Zahlen und Groß-/Kleinbuchstaben enthalten mind. 10 Zeichen):</label><br>
        <input type="password" v-model="firstLoginData.newPassword" placeholder="Neues Passwort">
        <input type="password" v-if="firstLoginData.newPassword" v-model="firstLoginData.newPassword2" placeholder="Passwort bestätigen"><br>
      </div>
      <button @click="newPassword">Passwort erstellen</button>
    </div>-->
    <TheNotification/>
    <TheFooter/>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
import VueJwtDecode from "vue-jwt-decode";
import { IsAdmin, IsViewer } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";
import CryptoJS from "crypto-js";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheNotification from "@/components/TheNotification.vue";
// Variables
const store = useStore();
const router = useRouter();
const user = new FormData();
let loading = ref(false);
let twofactor = ref(false);

// Login
const loginData = reactive({
  username: "",
  password: "",
  code: "",
});

// First login
const firstLoginData = reactive({
  oldUsername: "",
  oldPassword: "",
  newPassword: "",
  newPassword2: "",
  firstLogin: false,
});

// Functions
function submit() {
  if (!loginData.password || !loginData.username) return;
  const hash = CryptoJS.SHA256(loginData.username).toString();
  loading.value = true;
  user.append("username", loginData.username);
  user.append("password", loginData.password);
  if (twofactor.value) {
    if (!loginData.code) return;
    user.append("client_secret", loginData.code);
    store
      .dispatch("auth/checkCode", { user: user, hash: hash })
      .then(() => {
        const userRights = VueJwtDecode.decode(
          JSON.parse(localStorage.getItem("user")).access_token
        ).rights;
        if (IsAdmin(userRights)) {
          router.push("/admin");
        } else if (IsViewer(userRights)) {
          router.push("/view");
        } else {
          router.push("/home");
        }
        loading.value = false;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          notify("Server nicht gefunden", "error");
        } else if (err.response.status == 401) {
          localStorage.clear();
          notify(err.response.data.detail, "error");
        } else {
          notify("Something went wrong...", "error");
          console.log(err);
        }
        loading.value = false;
      });
    return;
  }
  if (JSON.parse(localStorage.getItem(`tfuser(${hash})`))) {
    store
      .dispatch("auth/notFirstLogin", { user: user, hash: hash })
      .then((res) => {
        if (res.code_sended) {
          twofactor.value = true;
          loading.value = false;
          return;
        }
        const userRights = VueJwtDecode.decode(
          JSON.parse(localStorage.getItem("user")).access_token
        ).rights;
        if (IsAdmin(userRights)) {
          router.push("/admin");
        } else if (IsViewer(userRights)) {
          router.push("/view");
        } else {
          router.push("/home");
        }
        loading.value = false;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          notify("Server nicht gefunden", "error");
        } else if (err.response.status == 401) {
          localStorage.clear();
          notify(err.response.data.detail, "error");
        } else {
          notify("Something went wrong...", "error");
          console.log(err);
        }
        loading.value = false;
      });
  } else {
    store
      .dispatch("auth/login", user)
      .then((res) => {
        if (res.code_sended) {
          twofactor.value = true;
          loading.value = false;
          return;
        }
        const userRights = VueJwtDecode.decode(
          JSON.parse(localStorage.getItem("user")).access_token
        ).rights;
        if (IsAdmin(userRights)) {
          router.push("/admin");
        } else if (IsViewer(userRights)) {
          router.push("/view");
        } else {
          router.push("/home");
        }
        loading.value = false;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          notify("Server nicht gefunden", "error");
        } else if (err.response.status == 401) {
          notify(err.response.data.detail, "error");
        } else {
          notify("Something went wrong...", "error");
          console.log(err);
        }
        loading.value = false;
      });
  }
}

//that may eventually go away will probably never happen :c
function firstLogin() {
  firstLoginData.firstLogin = !firstLoginData.firstLogin;
}
function newPassword() {
  if (firstLoginData.newPassword) {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
    if (firstLoginData.newPassword === firstLoginData.newPassword2) {
      if (re.test(firstLoginData.newPassword)) {
        user.append("username", firstLoginData.oldUsername);
        user.append("client_secret", firstLoginData.oldPassword);
        user.append("password", firstLoginData.newPassword);
        store
          .dispatch("auth/FirstLogin", user)
          .then((res) => {
            firstLogin();
            notify(res.response.data.detail, "success");
          })
          .catch((err) => notify(err.response.data.detail, "error"));
      } else {
        notify("Passwort wiederspricht Anforderungen", "error");
      }
    } else {
      notify("Passwort ist nicht gleich", "error");
    }
    firstLoginData.newPassword = null;
    firstLoginData.newPassword2 = null;
  }
}
</script>
<style scoped>
.firstlogin {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
.firstlogin h1 {
  font-size: 16px;
}
.firstlogin div {
  margin: 2px;
}
.firstlogin a {
  margin: 0;
}
a {
  color: blue;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}
.login {
  display: flex;
  margin: 0 auto;
  padding: 20px;
}

label {
  padding: 10px;
  padding-right: 5px;
}
input {
  margin: 1px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
input:hover {
  border-color: #aaa;
}
input:focus {
  border-color: #91bd2a;
  outline: none;
}
button {
  margin-left: 5px;
  border: 2px solid #91bd2a;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #91bd2a;
}
button:active {
  background-color: #5c7a14;
}
@media (max-width: 800px) {
  .firstlogin,
  .login {
    display: block;
  }
}
@media (max-width: 405px) {
  .firstlogin,
  .login {
    display: grid;
  }
}
</style>