import Admin from '../services/admin.service'

const AdminStates = {
    schedule: null,
    scheduleitem: null,
    cdpns: null,
    callees: null,
    calleeinf: null,
    calleepreset: null,
    regionid: 0,
    regions: null,
    regioninf: null,
    regiontts: null,
    log: null,
    calllist: null
}

export default {
    namespaced: true,
    state: AdminStates,
    getters: {
        Callees(state) {
            return state.callees;
        },
        CalleeInf(state) {
            return state.calleeinf;
        },
        CalleePreset(state) {
            return state.calleepreset;
        },
        RegionId(state) {
            return state.regionid;
        },
        Regions(state) {
            return state.regions;
        },
        RegionInf(state) {
            return state.regioninf;
        },
        RegionTTS(state) {
            return state.regiontts;
        },
        Schedule(state) {
            return state.schedule;
        },
        ScheduleItem(state) {
            return state.scheduleitem;
        },
        Cdpns(state) {
            return state.cdpns;
        },
        Log(state) {
            return state.log;
        },
        Calllist(state) {
            return state.calllist;
        }
    },
    actions: {
        getScheduleItem({ commit }, scheduleid) {
            return Admin.getScheduleItem(scheduleid).then(
                res => {
                    commit('setScheduleItem', { "item": res.data.item })
                    commit('setCdpns', { "cdpns": res.data.cdpns })
                    commit('setCallees', { "callees": res.data.callees })
                    commit('setCalleePreset', { "preset": res.data.preset })
                    return res.data.item
                },
            );
        },
        getSchedule({ commit }, filter) {
            return Admin.getSchedule(filter.filterdate, filter.region).then(
                res => {
                    commit('setSchedule', res.data)
                    return res.data
                }
            );
        },
        getCdpns({ commit }, calleeid) {
            return Admin.getCdpns(calleeid.calleeid).then(
                res => {
                    commit('setCdpns', res.data)
                    return res.data
                },
            );
        },
        getCallees({ commit }, region) {
            return Admin.getCallees(region.region).then(
                res => {
                    commit('setCallees', res.data)
                    return res.data
                }
            )
        },
        getSearchedCallees({ commit }, cname) {
            return Admin.getSearchedCallees(cname).then(
                res => {
                    commit('setCallees', res.data)
                    return res.data
                }
            )
        },
        getCalleeInf({ commit }, calleeid) {
            return Admin.getCalleeInf(calleeid).then(
                res => {
                    commit('setCallee', res.data);
                    return res.data
                }
            );
        },
        getCalleePreset({ commit }, calleeid) {
            return Admin.getCalleePreset(calleeid).then(
                res => {
                    commit('setCalleePreset', res.data);
                    return res.data
                }
            );
        },
        getCalleeAudio({ commit }, calleeid) {
            return Admin.getCalleeAudio(calleeid).then(res => { return res.data })
        },
        getCalleeAudioNoConn({ commit }, calleeid) {
            return Admin.getCalleeAudioNoConn(calleeid).then(res => { return res.data })
        },
        getRegions({ commit }) {
            return Admin.getRegions().then(
                res => {
                    commit('setRegions', res.data)
                    return res.data
                },
            );
        },
        getRegionInf({ commit }, regionid) {
            return Admin.getRegionInf(regionid).then(
                res => {
                    commit('setRegionInf', res.data);
                    return res.data
                }
            );
        },
        getRegionAudio({ commit }, regionid) {
            return Admin.getRegionAudio(regionid).then(res => { return res })
        },
        getRegionTTS({ commit }) {
            return Admin.getRegionTTS().then(
                res => {
                    commit('setRegionTTS', res.data);
                    return res.data
                }
            );
        },
        getLog({ commit }) {
            return Admin.getLog().then(
                res => {
                    commit('setLog', res.data)
                    return res.data
                }
            )
        },
        getCalllist({ commit }, filter) {
            return Admin.getCalllist(filter.filterdate, filter.regionid).then(
                res => {
                    commit('setCalllist', res.data)
                    return res.data
                }
            )
        },
        getLogSchedule({ commit }, filter) {
            return Admin.getLogSchedule(filter.filterdate, filter.regionid).then(
                res => {
                    commit('setLog', res.data)
                    return res.data
                }
            )
        },
        getLogRegion({ commit }, regionid) {
            return Admin.getLogRegion(regionid).then(
                res => {
                    commit('setLog', res.data)
                    return res.data
                }
            )
        },
        getScheduleEnds({ commit }) {
            return Admin.getScheduleEnds().then(
                res => {
                    return res
                }
            )
        },
        setRegionInf({ dispatch }, regioninf) {
            return Admin.setRegionInf(regioninf).then(res => { return res })
        },
        setCalleeInf({ dispatch }, calleeinf) {
            return Admin.setCalleeInf(calleeinf).then(res => { return res })
        },
        setCalleePreset({ dispatch }, calleepreset) {
            return Admin.setCalleePreset(calleepreset).then(res => { return res })
        },
        setCalleePw({ dispatch }, callee) {
            return Admin.setCalleePw(callee).then(res => { return res })
        },
        setStandCdpn({ dispatch }, callee) {
            return Admin.setStandCdpn(callee).then(res => { return res })
        },
        setReplCdpn({ dispatch }, callee) {
            return Admin.setReplCdpn(callee).then(res => { return res })
        },
        setScheduleItem({ commit }, scheduleitem) {
            return Admin.setScheduleItem(scheduleitem);
        },
        newSchedule({ dispatch }, file) {
            return Admin.newSchedule(file).then(res => { return res })
        },
        newCdpn({ dispatch }, callee) {
            return Admin.newCdpn(callee).then(res => {
                dispatch('getCalleeInf', callee.calleeid)
                return res
            })
        },
        newCallee({ dispatch }, callee) {
            return Admin.newCallee(callee).then(res => { return res })
        },
        newRegion({ dispatch }, region) {
            return Admin.newRegion(region).then(res => { return res })
        },
        newScheduleItem({ dispatch }, schedule) {
            return Admin.newScheduleItem(schedule).then(res => { return res })
        },
        deleteCdpn({ dispatch }, callee) {
            return Admin.deleteCdpn(callee).then(res => {
                dispatch('getCalleeInf', callee.calleeid)
                return res
            });
        },
        deleteCallee({ dispatch }, calleeid) {
            return Admin.deleteCallee(calleeid).then(res => { return res })
        },
        deleteRegion({ dispatch }, regionid) {
            return Admin.deleteRegion(regionid).then(res => { return res })
        },
        deleteSchedule({ dispatch }, scheduleid) {
            return Admin.deleteSchedule(scheduleid).then(res => { return res })
        },
        recover({ dispatch }, what) {
            return Admin.recover(what.what, what.id).then(res => {
                dispatch('getLog')
                return res
            });
        },
        splitSchedule({ dispatch }, schedules) {
            return Admin.setScheduleItem(schedules.scheduleitem).then(res => {
                dispatch('newScheduleItem', schedules.newschedule)
                return res
            });
        }
    },
    mutations: {
        setSchedule(state, schedule) {
            state.schedule = schedule.schedules;
        },
        setScheduleItem(state, scheduleitem) {
            state.scheduleitem = scheduleitem.item;
        },
        setCdpns(state, cdpns) {
            state.cdpns = cdpns.cdpns;
        },
        setCallees(state, callees) {
            state.callees = callees.callees;
        },
        setCallee(state, calleeinf) {
            state.calleeinf = calleeinf.calleeInf;
        },
        setCalleePreset(state, calleepreset) {
            state.calleepreset = calleepreset.preset;
        },
        setRegionId(state, regionid) {
            state.regionid = regionid;
        },
        setRegions(state, regions) {
            state.regions = regions.regions;
        },
        setRegionInf(state, regioninf) {
            state.regioninf = regioninf.regionInf;
        },
        setRegionTTS(state, regiontts) {
            state.regiontts = regiontts.tts;
        },
        setLog(state, log) {
            state.log = log.log;
        },
        setCalllist(state, calllist) {
            state.calllist = calllist.calllist;
        }
    },

}