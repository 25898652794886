<template>
  <div>
    <div class="filter" v-if="admin">
      <select v-model="filterRegion">
        <option disabled value="0">Region auswählen:</option>
        <option
          v-for="region in regions"
          :value="region.regionid"
          :key="region.regionid"
        >
          {{ region.name }}
        </option>
      </select>
      <input
        v-model="searchValue"
        placeholder="Arzt"
        @keyup.enter="SearchCallee"
      />
      <button v-if="filterRegion!=0" @click="Popup">Neuer Arzt</button>
      <button v-if="filterRegion!=0" @click="DelRegPopup">Region löschen</button>
      <button @click="PopupNewRegion">Neue Region</button>
      <button v-if="filterRegion!=0" @click="RegionInfPopup">Information</button>
      <button @click="GetScheduleEnds">Dienstende</button>
      <button v-if="callees" @click="downloadCSV(callees, 'Benuzter')">Download</button>
    </div>
    <EasyDataTable
      v-if="callees"
      :search-field="searchField"
      :search-value="searchValue"
      :headers="headers"
      :items="callees"
      @click-row="EditRow"
    />
    <Modal
      v-model:visible="isvisible"
      :okButton="safeButton"
      :cancelButton="cancelButton"
      :title="title"
    >
      <div>
        <div><label>Name: </label><input v-model="callee.name" /></div>
        <div><label>Username: </label><input v-model="callee.username" /></div>
        <div><label>Rechte: </label><input v-model="callee.rights" /></div>
        <div><label>Passwort: </label><input v-model="callee.password" /></div>
        <div><label>Nummer: </label><input v-model="callee.cdpn" /></div>
      </div>
    </Modal>
    <Modal
      v-model:visible="popupregion.isvisible"
      :okButton="popupregion.safe"
      :cancelButton="popupregion.cancel"
      :title="popupregion.title"
    >
      <div>
        <div><label>Name: </label><input v-model="region.rname" /></div>
      </div>
    </Modal>
    <Modal
      v-model:visible="delregpopup.isvisible"
      :okButton="delregpopup.yes"
      :cancelButton="delregpopup.no"
      :title="delregpopup.title"
    >
      <div>Wollen Sie die Region wirklich löschen?</div>
    </Modal>
    <Modal
      v-model:visible="regioninfpopup.isvisible"
      :okButton="regioninfpopup.safe"
      :cancelButton="regioninfpopup.cancel"
      :title="regioninfpopup.title"
      :width="regioninfpopup.width"
    >
      <div v-if="regioninf" class="popupdiv">
        <div class="textareas" v-if="!loading">
          <div class="rname">
            <input v-model="regioninf.rname" />
          </div>
          <label>Ansage </label><br />
          <textarea v-model="regioninf.audio" @focus="Resize"></textarea>
          <audio
            :src="audiourls[0]"
            controls
            v-if="!loading && audiourls"
          ></audio
          ><br />
          <label>Außerhalb 1 </label><br />
          <textarea
            v-model="regioninf.audionodutyone"
            @focus="Resize"
          ></textarea>
          <audio
            :src="audiourls[1]"
            controls
            v-if="!loading && audiourls"
          ></audio
          ><br />
          <label>Außerhalb 2 </label><br />
          <textarea
            v-model="regioninf.audionodutytwo"
            @focus="Resize"
          ></textarea>
          <audio
            :src="audiourls[2]"
            controls
            v-if="!loading && audiourls"
          ></audio
          ><br />
          <label>Erinnerung </label><br />
          <textarea v-model="regioninf.reminder" @focus="Resize"></textarea
          ><br />
          <audio
            :src="audiourls[3]"
            controls
            v-if="!loading && audiourls"
          ></audio
          ><br />
          <label>Absage </label><br />
          <textarea
            v-model="regioninf.audionoconnect"
            @focus="Resize"
          ></textarea
          ><br />
          <audio
            :src="audiourls[4]"
            controls
            v-if="!loading && audiourls"
          ></audio
          ><br />
          <select v-model="regioninf.tts">
            <option v-for="item in tts" :key="item">{{ item }}</option>
          </select>
          <label> ID: {{ regioninf.regionid }}</label><br />
          <label>Notizen: </label><br /><textarea
            v-model="regioninf.remarks"
            @focus="Resize"
          ></textarea>
        </div>
        <TheLoadingAnimation v-if="loading" />
      </div>
    </Modal>
  </div>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, watch, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsAdmin } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";
import { downloadCSV } from "@/store/functions/downloadCSV";

//variables
//vue
const store = useStore();
const router = useRouter();
const props = defineProps({ call: Boolean });
const emit = defineEmits(["edit", "update"]);
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
//table
const callees = computed(() => {
  return store.getters["admin/Callees"];
});
const regions = computed(() => {
  return store.getters["admin/Regions"];
});
const headers = [
  { text: "Nutzername", value: "username" },
  { text: "Name", value: "name" },
];
const searchField = ["name"];
let searchValue = ref();
//filter
let filterRegion = ref(store.getters["admin/RegionId"]);
//popup
const audiourls = ref([]);
const regioninf = computed(() => {
  return store.getters["admin/RegionInf"];
});
const tts = computed(() => {
  return store.getters["admin/RegionTTS"];
});
let loading = ref(true);
let updated = ref(false);
const callee = reactive({
  name: "",
  region: "",
  username: "",
  rights: 1,
  password: "",
  cdpn: "",
});
const region = reactive({ rname: "" });

//init
if (admin) {
  store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
  if (filterRegion.value) {
    FilterRegion()
  }
}

//functions
//filter
function FilterRegion() {
  store
    .dispatch("admin/getCallees", { region: filterRegion.value })
    .catch((err) => OnError(err, router));
}
//table
function EditRow(row) {
  emit("edit", row.calleeid);
}
//deleteRegion
function DeleteRegion() {
  store
    .dispatch("admin/deleteRegion", filterRegion.value)
    .then((res) => {
      filterRegion.value = "";
      store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
      notify(res.data.detail, "success");
    })
    .catch((err) => OnError(err, router));
  delregpopup.isvisible = false;
}
//getScheduleEnds
function GetScheduleEnds() {
  store
    .dispatch("admin/getScheduleEnds")
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Dienstende.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => OnError(err, router));
}
//SearchCallee
function SearchCallee() {
  filterRegion.value = "";
  store
    .dispatch("admin/getSearchedCallees", searchValue.value)
    .catch((err) => OnError(err, router));
    searchValue.value = "";
}

//Popups
// Popup NewCallee
function NewCallee() {
  const re = /^[0-9*#]+$/;
  if (!re.test(callee.cdpn)) {
    notify("Nur Ziffern erlaubt", "error");
    callee.cdpn = null;
    return;
  }
  store
    .dispatch("admin/newCallee", callee)
    .then((res) => {
      notify(res.data.detail, "success");
      FilterRegion();
      for (const key in callee) {
        callee[key] = "";
      }
      callee.rights = 1;
    })
    .catch((err) => {
      OnError(err, router);
    });
  isvisible.value = false;
}
const isvisible = ref(false);
const title = "Neuer Arzt";
const safeButton = { text: "Speichern", onclick: NewCallee };
const cancelButton = { text: "Abbrechen", onclick: null };
function Popup() {
  callee.region = filterRegion.value;
  isvisible.value = true;
}
// Popup NewRegion
function NewRegion() {
  store
    .dispatch("admin/newRegion", region)
    .then((res) => {
      notify(res.data.detail, "success");
      store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
      for (const key in region) {
        region[key] = "";
      }
    })
    .catch((err) => {
      OnError(err, router);
    });
  popupregion.isvisible = false;
}
const popupregion = reactive({
  isvisible: ref(false),
  title: "Neue Region",
  safe: { text: "Speichern", onclick: NewRegion },
  cancel: { text: "Abbrechen", onclick: null },
});
function PopupNewRegion() {
  popupregion.isvisible = true;
}
//Popup RegionInf
function Resize(event) {
  const textarea = event.target;
  textarea.style.height = textarea.scrollHeight - 20 + "px";
}
function GetRegionInf() {
  loading.value = true;
  store.dispatch("admin/getRegionInf", filterRegion.value).catch((err) => {
    OnError(err, router);
  });
  store.dispatch("admin/getRegionTTS").catch((err) => {
    OnError(err, router);
  });
  if (audiourls.value)
    audiourls.value.forEach((audiourl) => {
      URL.revokeObjectURL(audiourl);
    });
  audiourls.value = null;
  store
    .dispatch("admin/getRegionAudio", filterRegion.value)
    .then((responses) => {
      const urls = [];
      responses.forEach((res) => {
        const blob = new Blob([res.data], { type: "audio/mpeg" });
        const url = URL.createObjectURL(blob);
        urls.push(url);
        audiourls.value = urls;
        loading.value = false;
      });
    })
    .catch((err) => {
      loading.value = false;
      console.log(err);
      notify("Audio nicht vorhanden", "error");
    });
}
function SetRegionInf() {
  if (updated.value) {
    loading.value = true;
    store
      .dispatch("admin/setRegionInf", regioninf.value)
      .then((res) => {
        notify(res.data.detail, "success");
        GetRegionInf();
      })
      .catch((err) => {
        OnError(err, router);
        loading.value = false;
      });
    updated.value = false;
  }
}
const regioninfpopup = reactive({
  isvisible: false,
  width: "75%",
  title: "Regions Information",
  safe: { text: "Speichern", onclick: SetRegionInf },
  cancel: { text: "Abbrechen", onclick: null },
});
function RegionInfPopup() {
  regioninfpopup.isvisible = true;
  GetRegionInf();
}
//Delete Region
const delregpopup = reactive({
  title: "Region löschen?",
  isvisible: false,
  yes: { text: "Ja", onclick: DeleteRegion },
  no: { text: "Nein", onclick: null },
  width: "35%",
});
function DelRegPopup() {
  delregpopup.isvisible = true;
}

//watchers
watch(filterRegion, (newvalue, oldvalue) => {
  searchValue.value = "";
  store.commit("admin/setRegionId", newvalue);
  if (filterRegion.value) FilterRegion();
});
watch(
  () => regioninf,
  (newValue, oldValue) => {
    updated.value = true;
  },
  { deep: true }
);
watch(
  () => props.call,
  (call) => {
    if (call) {
      filterRegion = ref(store.getters["admin/RegionId"]);
      store.dispatch("admin/getCallees", { region: filterRegion.value });
      emit("update");
    }
  }
);
</script>
<style scoped>
a {
  cursor: pointer;
}
a:hover {
  color: #91bd2a;
}
.filter {
  margin: 2px;
}
.popupdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textareas {
  width: 90%;
}
audio {
  border-radius: 0 0 5px 5px;
}
textarea {
  border-radius: 5px 5px 0 0;
  width: 98%;
}
.rname {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rname input {
  width: 100%;
}
</style>