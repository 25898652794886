<template>
  <div
    v-if="message"
    class="notification"
    :style="{ backgroundImage: `url(${icon})` }"
  >
    <p>{{ message }}</p>
  </div>
</template>
  
  <script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps(["message", "icon"]);
const { message, icon } = toRefs(props);
</script>
  
  <style scoped>
.notification {
  font-size: 1.1dvw;
  display: flex;
  align-items: center;
  padding: 0.8dvw;
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 3.5dvw;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.notification p {
  margin-left: 4vw;
}
@media (max-width: 1200px) {
  .notification {
    font-size: 1.5vw;
    background-size: 3.7dvw;
  }
  .notification p {
    margin-left: 4.5vw;
  }
}
@media (max-width: 800px) {
  .notification p {
    margin-left: 5vw;
  }
}
@media (max-width: 600px) {
  .notification {
    background-size: 3.9dvw;
    font-size: 2vw;
  }
  .notification p {
    margin-left: 5.5vw;
  }
}
</style>
  