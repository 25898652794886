<template lang="">
    <div>
      <div class="upload">
        <input v-if="admin" type="file" ref="file">
        <button @click="UploadSchedule" v-if="admin">Hochladen</button>
        <button v-if="filterRegion!=0&&admin" @click="Popup">Neuer Dienst</button>
        <button v-if="filterRegion!=0&&admin" @click="downloadCSV(scheduleItems, 'Dienstplan')">Download</button>
      </div>
        <div class="filter">
        <select v-if="callees&&filterRegion!=0||!admin" v-model="searchValue">
            <option value="">Alle</option>
            <option v-for="callee in callees" :key="callee.name" :value="callee.name">{{callee.name}}</option>
        </select>
        <select v-if="admin&&regions" v-model="filterRegion" @change="FilterRegion">
            <option disabled value="0">Region auswählen:</option>
            <option v-for="region in regions" :value="region.regionid" :key="region.regionid">{{region.name}}</option>
        </select>
        <input v-if="filterRegion!=0||!admin" type="date" v-model="filterDate" @blur="Filter" class="datepicker">
        <button v-if="filterRegion!=0||!admin" @click="Filter">Aktualisieren</button>
        <button v-if="!admin" @click="downloadCSV(scheduleItems, 'Dienstplan')">Download</button>
      </div>
        <EasyDataTable v-if="scheduleItems&&(filterRegion!=0||!admin)"
        :search-field="searchField"
        :search-value="searchValue"
        :headers="headers"
        :items="scheduleItems"
        @click-row="EditRow"
  />
  <Modal v-model:visible="isvisible" :okButton=yesButton :cancelButton=noButton :title=title>
    <div>
      <div><label>Start: </label><input type="datetime-local" v-model="startdate"></div>
      <div><label>Ende: </label><input type="datetime-local" v-model="enddate"></div>
      <div><label>Arzt: </label><select v-model="popupcallee" v-if="admin" @change='GetCdpns'>
        <option v-if="admin" value="" disabled>Arzt auswählen</option>
        <option v-for="item in callees" :key="item.calleeid" :value="item.calleeid">{{item.name}}</option>
      </select></div>
      <div><label>Nummer: </label><select v-model="popupcdpn"  v-if="admin">
        <option v-for="item in cdpns" :key="item" :value="item">{{item}}</option>
      </select></div>
      <div>
        <label>Voreinstellung nutzen: </label><input type="checkbox" v-model="usepreset">
        </div>
    </div>
  </Modal>
    </div>
</template>
<script setup>
import VueJwtDecode from "vue-jwt-decode";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "usemodal-vue3";
import { OnError } from "@/store/functions/errorHandler";
import { IsUser, IsViewer, IsAdmin } from "@/store/functions/rights";
import { notify } from "@/store/functions/notifications";
import { downloadCSV } from "@/store/functions/downloadCSV";

//variables
//vue
const emit = defineEmits(["edit", "update"]);
const props = defineProps({ call: Boolean });
const store = useStore();
const router = useRouter();
//table
let regions = ref();
let scheduleItems = ref();
let callees = ref();
let cdpns = ref();
const searchField = ["callee"];
const headers = [
  { text: "Start", value: "startdate" },
  { text: "Ende", value: "enddate" },
  { text: "Arzt", value: "callee" },
  { text: "Nummer", value: "cdpn" },
];
//filter
const currentdate = new Date();
const timezonems = currentdate.getTimezoneOffset() * 60000;
const localdate = new Date(currentdate.getTime() - timezonems);
const formatteddate = localdate.toISOString().slice(0, 10);
let filterDate = ref(formatteddate);
let searchValue = ref("");
let filterRegion = ref(store.getters["admin/RegionId"]);
//rights
const tokendecoded = VueJwtDecode.decode(
  JSON.parse(localStorage.getItem("user")).access_token
);
const userRights = tokendecoded.rights;
const admin = IsAdmin(userRights);
const user = IsUser(userRights);
const viewer = IsViewer(userRights);
//popup
const localformatteddate = localdate.toISOString().slice(0, 16);
let popupcallee = ref();
let popupcdpn = ref("");
let startdate = ref(localformatteddate);
let enddate = ref(localformatteddate);
let usepreset = ref(true);
//fileupload
let file = ref();
let files = new FormData();

//init
if (admin) {
  regions = computed(() => {
    return store.getters["admin/Regions"];
  });
  cdpns = computed(() => {
    return store.getters["admin/Cdpns"];
  });
  callees = computed(() => {
    return store.getters["admin/Callees"];
  });
  scheduleItems = computed(() => {
    return store.getters["admin/Schedule"];
  });
  store.dispatch("admin/getRegions").catch((err) => OnError(err, router));
  if (filterRegion.value) {
    FilterRegion()
  }
} else {
  cdpns = computed(() => {
    return store.getters["edit/Cdpns"];
  });
  callees = computed(() => {
    return store.getters["sched/Callees"];
  });
  scheduleItems = computed(() => {
    return store.getters["sched/ScheduleItems"];
  });
  store.dispatch("sched/getCallees").catch((err) => OnError(err, router));
  store
    .dispatch("sched/getSchedule", { filterdate: filterDate.value })
    .catch((err) => OnError(err, router));
}

//functions
//table
function ConvertDate(rawdate) {
  rawdate = rawdate.split(" ");
  let time = rawdate[2];
  rawdate = rawdate[1].split(".");
  let date = `${rawdate[2]}-${rawdate[1]}-${rawdate[0]}T${time}`;
  return date;
}
function EditRow(row) {
  if (ConvertDate(row.enddate) < formatteddate)
    notify("Dienste in der Vergangenheit sind gesperrt", "error");
  else emit("edit", row.scheduleid, filterDate.value);
}
//filter
function Filter() {
  if (admin) {
    store
      .dispatch("admin/getSchedule", {
        filterdate: filterDate.value,
        region: filterRegion.value,
      })
      .catch((err) => OnError(err, router));
  } else {
    store
      .dispatch("sched/getSchedule", { filterdate: filterDate.value })
      .catch((err) => OnError(err, router));
  }
}
function FilterRegion() {
  if (admin) {
    store
      .dispatch("admin/getSchedule", {
        filterdate: filterDate.value,
        region: filterRegion.value,
      })
      .catch((err) => OnError(err, router));
    store
      .dispatch("admin/getCallees", { region: filterRegion.value })
      .catch((err) => OnError(err, router));
  }
}
//fileupload
function UploadSchedule() {
  if (!file.value.value) return;
  console.log(file.value.files);
  files.append("file", file.value.files[0]);
  store
    .dispatch("admin/newSchedule", files)
    .then((res) => notify(res.data.detail, "success"))
    .catch((err) => OnError(err, router));
  files = new FormData();
  file.value.value = null;
}

//popups
function GetCdpns() {
  if (admin) {
    popupcdpn.value = "";
    store
      .dispatch("admin/getCdpns", { calleeid: popupcallee.value })
      .then((res) => (popupcdpn.value = res.cdpns[0]))
      .catch((err) => OnError(err, router));
  }
}
//popup newschedule
function NewSchedule() {
  let newschedule = {
    calleeid: popupcallee.value,
    cdpn: popupcdpn.value,
    startdate: startdate.value,
    enddate: enddate.value,
    scheduleid: 0,
    usepreset: usepreset.value,
  };
  store
    .dispatch("admin/newScheduleItem", newschedule)
    .then((res) => {
      notify(res.data.detail, "success");
      Filter();
      isvisible.value = false;
    })
    .catch((err) => OnError(err, router));
}
let isvisible = ref(false);
const title = "Neuer Dienst";
const yesButton = { text: "Ja", onclick: NewSchedule };
const noButton = { text: "Nein", onclick: null };
function Popup() {
  isvisible.value = true;
}

//watchers
watch(filterRegion, (newvalue, oldvalue) => {
  store.commit("admin/setRegionId", newvalue);
});
watch(
  () => props.call,
  (call) => {
    if (call) {
      Filter();
      emit("update");
    }
  }
);
</script>
<style scoped>
.upload {
  margin: 2px;
}
.filter {
  margin: 2px;
  display: flex;
}
@media (max-width: 800px) {
  .filter,
  .upload {
    display: block;
  }
}
@media (max-width: 405px) {
  .filter,
  .upload {
    display: grid;
  }
}
</style>