import Schedule from '../services/sched.service';
import Edit from '../services/edit.service';

const scheduleState = { items: null, callees: null }

export default {
    namespaced: true,
    state: scheduleState,
    getters: {
        ScheduleItems(state) {
            return state.items;
        },
        Callees(state) {
            return state.callees;
        },
    },
    actions: {
        getSchedule({ commit }, filterDate) {
            return Schedule.get(filterDate.filterdate).then(
                res => {
                    commit('setItems', res.data)
                }
            );
        },
        getScheduleView({ commit }, filterDate) {
            return Schedule.getView(filterDate.filterdate).then(
                res => {
                    commit('setItems', res.data)
                }
            );
        },
        getCallees({ commit }) {
            return Edit.getCallees().then(
                res => {
                    commit('setCallees', res.data)
                },
            );
        },
    },
    mutations: {
        setItems(state, items) {
            state.items = items.schedules;
        },
        setCallees(state, callees) {
            state.callees = callees.callees;
        },
    },
}