import Coredata from '../services/coredata.service'
import Edit from '../services/edit.service'

const CoredataStates = {
    cpdns: null,
}

export default {
    namespaced: true,
    state: CoredataStates,
    getters: {
        Cdpns(state) {
            return state.cdpns;
        },
    },
    actions: {
        getCdpns({ commit }) {
            return Edit.getCdpns().then(
                res => {
                    commit('setCdpns', res.data);
                    return res.data
                }
            );
        },
        getAudio({ commit }) {
            return Coredata.getAudio().then(res => {
                return res.data
            })
        },
        getAdress({ commit }) {
            return Coredata.getAdress().then(res => {
                return res.data
            })
        },
        newCdpn({ dispatch }, cdpn) {
            return Coredata.newCdpn(cdpn).then(res => {
                dispatch('getCdpns')
                return res
            }
            );
        },
        deleteCdpn({ dispatch }, cdpn) {
            return Coredata.deleteCdpn(cdpn).then(res => {
                dispatch('getCdpns')
                return res
            }
            );
        },
        setStandCdpn({ dispatch }, cdpn) {
            return Coredata.setStandCdpn(cdpn).then(res => {
                dispatch('getCdpns')
                return res
            }
            );
        },
        setPassword({ dispatch }, password) {
            return Coredata.setPassword(password)
        }
    },
    mutations: {
        setCdpns(state, cdpns) {
            state.cdpns = cdpns.cdpns;
        },
    },
}