<template>
  <TheHeader />
  <nav>
    <button @click="Contact">Kontakt</button>
    <button @click="Logout">Logout</button>
  </nav>
  <main class="view">
    <keep-alive>
      <TheSchedule
        v-if="!togglecomp"
        :call="state.call"
        @update="Update"
        @edit="Edit"
      />
    </keep-alive>
    <TheScheduleEdit v-if="togglecomp" :scheduleid="scheduleid" @edit="Edit" />
  </main>
  <TheFooter />
</template>
  
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, reactive } from "vue";
import TheSchedule from "@/components/TheSchedule.vue";
import TheScheduleEdit from "@/components/TheScheduleEdit.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
const store = useStore();
const router = useRouter();
function Logout() {
  store.dispatch("auth/logout").then(router.push("/"));
}
function Contact() {
  window.open("https://www.priokom.de/kontakt/anfrage", "_contact");
}

let togglecomp = ref(false);
let scheduleid = ref(0);
const state = reactive({ call: false });

function Update() {
  state.call = false;
}

function Edit(schedule) {
  scheduleid.value = schedule;
  togglecomp.value = !togglecomp.value;
  if (!togglecomp.value) state.call = true;
}
</script>
  
  <style scoped>
.view {
  margin-bottom: 4.6vw;
}
button {
  width: 20%;
}
</style>
  